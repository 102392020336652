import React from "react";
import Button from "../../button";
import ButtonSecondary from "components/platform/buttonSecondary";
import { Link } from "react-router-dom";

const CreatedButtonBar = () => {

    return (
        <div className={`absolute top-0 w-full pointer-events-auto z-10 transition-all -translate-y-full block lg:hidden`}>
            {
                (window.location.href.includes("myNFTs") && !window.location.href.includes("#Edit") && !window.location.href.includes("#Sell")) &&
                <div className="shadow-bottom-px bg-theme-black text-white shadow-bottom-px py-3 px-4 text-center w-full text-base">

                    <div className="flex w-full gap-x-4 items-center justify-between">
                        <Link
                            replace
                            to={`#Edit`}
                            className="w-full"
                        >
                            <ButtonSecondary paddingOff className="w-full flex justify-center p-2 font-500 items-center">Edit</ButtonSecondary>
                        </Link>
                        <Button paddingOff onClick={() => {
                        }} className="w-full flex justify-center p-2 font-500 items-center">
                            Sell
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
}

export default CreatedButtonBar
