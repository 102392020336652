import React from "react";
import style from "./protrade.module.scss";

const UnderlineSwitch = ({switchItems, activeTab, setActiveTab}) => {

  return (
    <div className="flex">
      {switchItems.map((item, index) => (
        <div
          key={item.label}
          className={`${style.historiMargin} ${
            activeTab === index ? style.blueBorder : ""
          } cursor-pointer`}
          onClick={() => setActiveTab(index)}
        >
          <p
            className={`${
                activeTab === index ? "" : "text-black-50"
            } text-base`}
          >
            {item.label}
          </p>
        </div>
      ))}
    </div>
  );
};

export default UnderlineSwitch;
