import React from "react";

const ChartIcon = ({fill}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 17.5H3.33333C2.8731 17.5 2.5 17.1269 2.5 16.6667V2.5H4.16667V15.8333H17.5V17.5ZM6.9775 13.3333L5.83333 12.2133L9.88 8.25C10.1986 7.9404 10.7056 7.9404 11.0242 8.25L12.88 10.0675L16.3558 6.66667L17.5 7.78667L13.4533 11.75C13.1348 12.0596 12.6277 12.0596 12.3092 11.75L10.4525 9.93167L6.9775 13.3333Z"
        fill = {fill}
      />
    </svg>
  );
};

export default ChartIcon
