import axios from "axios";
import { TokenInfo, PairInfo } from "components/platform/swapForm/PairsList";
import { datamigration } from "googleapis/build/src/apis/datamigration";
import { pair } from "ramda";
import React, { MutableRefObject, ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import tokensDictionary from "constants/tokensDictionary.json"
import assetsInfo from "constants/assetsInfo.json"
import pairsInfo from "constants/pairsInfo.json"
const TokenContext = React.createContext<
    {
        currentPair: any
        setCurrentPair: React.Dispatch<any>,
        assetsInfo: { [key: string]: TokenInfo },
        assetsInfoLoading: boolean,
        pairsInfo: { [key: string]: PairInfo },
        sortedTokensDictionary: string[],
        getPair: (token1: string, token2: string) => PairInfo[]
    }
>({
    currentPair: null,
    setCurrentPair: (arg) => { },
    assetsInfo: {},
    assetsInfoLoading: true,
    pairsInfo: {},
    sortedTokensDictionary: [],
    getPair: (token1: string, token2: string) => []
});

interface Props {
    children: ReactNode;
}

const limit = 2000

const makePairsQuery = (page = 0) => `
    pair(limit: ${limit}${page ? `, skip: ${page * limit}` : ""}) {
      content {
        token0Address
        token1Address
        liquidityTokenAddress
        contractAddr
        market
      }
      pageInfo {
        hasNextPage
      }
    }
`

const makeTokensQuery = (page = 0) => `
token(limit: ${limit}${page ? `, skip: ${page * limit}` : ""}) {
    content {
      contractAddr
      decimals
      icon
      market
      name
      symbol
      totalSupply
    }
    pageInfo {
      hasNextPage
    }
  }
`
const makeLiquidityQuery = (addresses = "") => `
    period(contractAddrList: "${addresses}", token0: "uusd") {
        content {
        contractAddr
        liquidity
        }
        pageInfo {
        hasNextPage
        count
        }
    }
`

const TokenContextProvider = ({ children }: Props) => {
    const [currentPair, setCurrentPair] = useState(null)
    const [pairsWithMarkets, setPairsWithMarkets] = useState<{ [key: string]: PairInfo[] }>({})
    const [assetsInfoLoading, setAssetsInfoLoading] = useState(true);
    const [sortedTokensDictionary, setSortedTokensDictionary] = useState([])

    const sortTokensByLiquidity = async () => {
        const tokensWithoutUstPair = []

        const addresses = Object.keys(tokensDictionary).reduce((prev, curren, index) => {
            if (tokensDictionary[curren].ustPairs.length === 0) {
                tokensWithoutUstPair.push(curren)
                return prev
            }

            return [...prev, ...tokensDictionary[curren].ustPairs]
        }, [])

        const response = await axios.post(process.env.REACT_APP_ANALYTICS_GQL_API, {
            query: ` 
            query q{
              period(contractAddrList: "${addresses.join(",")}") {
                content {
                  liquidity
                  token0Address
                  token1Address
                }
              }
            }
            `})

        const data = response.data.data.period.content

        const tokenToInfo = data.reduce((result, current) => {
            const address: string = [current.token0Address, current.token1Address].find(item => item !== "uusd")

            if (!address)
                return result

            result[address] = {
                liquidity: (result?.[address]?.liquidity ?? 0) + parseFloat(current.liquidity),
            }

            return result
        }, {})

        const sorted = Object.keys(tokenToInfo).sort((a, b) => tokenToInfo[b].liquidity - tokenToInfo[a].liquidity)

        const union = [...sorted, ...tokensWithoutUstPair]

        setSortedTokensDictionary(union)
    }

    const getTokens = async () => {
        try {
            const pairsWithArrays: { [key: string]: PairInfo[] } = {}

            for (let key of Object.keys(pairsInfo)) {
                const t1 = assetsInfo[pairsInfo[key].token0Address]
                const t2 = assetsInfo[pairsInfo[key].token1Address]

                if (!t1 || !t2) {
                    continue
                }

                const addresses = [t1.contractAddr, t2.contractAddr].sort().join("-")

                pairsWithArrays[addresses] = [...(pairsWithArrays[addresses] ?? []), pairsInfo[key]]
            }

            // setAssetsInfo(tokens)
            // setPairsInfo(pairs)
            setPairsWithMarkets(pairsWithArrays)
            setAssetsInfoLoading(false)
        } catch (e) {
            console.log("%c" + e.message, "color:red")
        }
    }

    useLayoutEffect(() => {
        getTokens()
        // sortTokensByLiquidity()
    }, [])

    const getPair = useCallback((token0: string, token1: string): PairInfo[] => pairsWithMarkets[[token0, token1].sort().join("-")] ?? []
        , [pairsWithMarkets])


    return (
        <TokenContext.Provider
            value={{
                currentPair,
                setCurrentPair,
                assetsInfo,
                assetsInfoLoading,
                pairsInfo,
                sortedTokensDictionary,
                getPair
            }}
        >
            {children}
        </TokenContext.Provider>
    );
};


export default TokenContext;

export { TokenContextProvider };
