import { any } from 'ramda'
import React, { MutableRefObject, useCallback, useLayoutEffect, useRef, useState } from 'react'
import Accordion from './accordion'
import ArrowDownIcon from './icons/arrowDown'

type IDropdownBaseElements = "selected" | "arrow" | "container" | "optionsContainer" | "displayRow"

interface IDropdownBaseProps {
  children: (data: any, index: number, isSelected: boolean) => JSX.Element,
  options: any[],
  onChange: (value) => void
  value: any
  defaultSelectedIndex?: number
  disabled?: boolean
  classNames?: Partial<Record<IDropdownBaseElements, ({ open, disabled }: { open: boolean, disabled: boolean }) => string>>
  selectedElement?: (data, className?: string) => JSX.Element,
  arrowElement?: (open: boolean, className?: string) => JSX.Element,
  notRelativeContainer?: boolean
}

const defaultSelectedElement = (data, className) => <div {...{ className }}>{data?.label ?? ""}</div>
const defaultArrowElement = (open, className) => <div {...{ className }}><div className={`flex transition-all ${open ? "rotate-z-180" : ""}`}><ArrowDownIcon /></div></div>

const DropdownBase = ({
  options = [],
  children,
  onChange,
  value = null,
  disabled = false,
  classNames = { optionsContainer: () => "w-full" },
  defaultSelectedIndex = 0,
  selectedElement = defaultSelectedElement,
  arrowElement = defaultArrowElement,
  notRelativeContainer,
}: IDropdownBaseProps) => {

  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex >= 0 ? defaultSelectedIndex : 0)

  const containterRef = useRef() as MutableRefObject<HTMLDivElement>

  const toggleOpen = useCallback(() => {
    if (disabled)
      return

    setOpen(prev => !prev)
  }, [disabled])

  useLayoutEffect(() => {
    const handelClickOutside = (e: MouseEvent) => {
      // @ts-ignore
      const closest = e.target.closest("[data-dropdown-base]")
      if (!closest || closest !== containterRef.current)
        setOpen(false)
    }

    if (open) {
      document.addEventListener("click", handelClickOutside)
    }
    else {
      document.removeEventListener("click", handelClickOutside)
    }

    return () => {
      document.removeEventListener("click", handelClickOutside)
    }
  }, [open])

  const getClassname = (name: IDropdownBaseElements) => classNames[name] ? classNames[name]({ open, disabled }) : ""

  return (
    <div ref={containterRef} data-dropdown-base className={`${notRelativeContainer ? '' : 'relative'} ${getClassname("container")}`}>
      <div className={`grid-cols-fr-max ${disabled ? "cursor-default" : "cursor-pointer"} ${getClassname("displayRow")}`} onClick={toggleOpen}>
        <div className='w-full select-none my-auto'>
          {value && selectedElement(value, getClassname("selected"))}
        </div>
        <div className='flex items-center'>
          {arrowElement(open, getClassname("arrow"))}
        </div>
      </div>
      <div className={`absolute overflow-hidden left-0 z-30 ${getClassname("optionsContainer")}`}>
        <Accordion {...{ open }} className='h-max-36 overflow-y-auto scrollbar-tune'>
          {options.map((item, index) => React.cloneElement(
            children(item, index, index === selectedIndex),
            {
              onClick: () => {
                setSelectedIndex(index)
                setOpen(false)
                onChange(item)
              },
              key: index
            }
          ))}
        </Accordion>
      </div>
    </div>
  );
}

export default DropdownBase
