import React, { StrictMode } from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/index.scss";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";
import Network from "./layouts/Network";
import Contract from "./layouts/Contract";
import App from "./layouts/App";
import WalletConnectProvider from "./layouts/WalletConnectProvider";
import { TokenContextProvider } from "./layouts/TokenContext";
import { MenuContextProvider } from "components/platform/context/menu";
import Layout from "components/platform/layout";
import MobileMenu from "components/platform/layout/mobileMenu";
import { PairContextProvider } from "components/platform/context/pair";
import { PageLoaderContextProvider } from "components/platform/context/pageLoader";
import { NFTFilterContextProvider } from "components/platform/context/nftFilter";
import { SnackbarProvider } from "notistack";
import { NFTContextProvider } from "components/platform/context/nft";
import { LimitContextProvider } from "components/platform/context/limit";
import { CancelLimitModalContextProvider } from "components/platform/context/cancelLimitModal";
import { NFTUsersContextProvider } from "components/platform/context/usersContext";
import { CreateNFTContextProvider } from "components/platform/context/createNFTContext";
import { ResizeContextProvider } from "components/platform/context/resize";
import { FavoriteContextProvider } from "components/platform/context/favorite";
import { SwapContextProvider } from "components/platform/context/swap";
import { ProjectsContextProvider } from "./layouts/ProjectsContext";
import { ProSwapContextProvider } from "./components/platform/context/proSwap";
import { ThemeContextProvider } from "./components/platform/context/themeContext";
import { SidePanelContextProvider } from './components/platform/context/sidePanel'
import { USTPriceContextProvider } from './components/platform/context/USTprice'


render(
  <StrictMode>
    <PageLoaderContextProvider>
      <ThemeContextProvider>
        <USTPriceContextProvider>
          <NFTFilterContextProvider>
            <WalletConnectProvider>
              <TokenContextProvider>
                <ProjectsContextProvider>
                  <PairContextProvider>
                    <Network>
                      <Contract>
                        <SnackbarProvider maxSnack={10}>
                          <NFTContextProvider>
                            <SwapContextProvider>
                              <LimitContextProvider>
                                <CancelLimitModalContextProvider>
                                  <FavoriteContextProvider>
                                    <SidePanelContextProvider>
                                      <Router>
                                        <MenuContextProvider>
                                          <MobileMenu>
                                            <Layout>
                                              <App />
                                            </Layout>
                                          </MobileMenu>
                                        </MenuContextProvider>
                                      </Router>
                                    </SidePanelContextProvider>
                                  </FavoriteContextProvider>
                                </CancelLimitModalContextProvider>
                              </LimitContextProvider>
                            </SwapContextProvider>
                          </NFTContextProvider>
                        </SnackbarProvider>
                      </Contract>
                    </Network>
                  </PairContextProvider>
                </ProjectsContextProvider>
              </TokenContextProvider>
            </WalletConnectProvider>
          </NFTFilterContextProvider>
        </USTPriceContextProvider>
      </ThemeContextProvider>
    </PageLoaderContextProvider>
  </StrictMode>,
  document.getElementById("terraformer")
);
