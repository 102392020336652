import React, { useCallback, useState } from "react";
import { useAddress, useConnectModal } from "hooks";
import Button from "components/platform/button";
import ProTradeSwitchRow from "./ProTradeSwitch";
import SwitchRowItem from "components/platform/tableSwitch";
import FavoriteTokenList from "./favoriteTokenList";
import FavoritePoolsList from "./favoritePoolsList";


const switchTitles = ["Tokens", "Pairs"];


const FavoritesPoolsAndTokens = ({ isGraph }) => {
  const address = useAddress();
  const connectModal = useConnectModal();
  const [activeTxTab, setActiveTxTab] = useState(0);

  const openConnect = useCallback(() => connectModal.open(), []);
  return (
    <div className="w-full h-full">
      {!address ? (
        <div className="px-4 my-4">
          <Button className="w-full" onClick={openConnect}>
            Connect Wallet
          </Button>
        </div>
      ) : (
        <div className="grid-rows-max-fr h-full">
          <div className={`flex w-full pt-4 xl:pt-0 pl-3 pr-11`}>
            <div className="flex-basis-40">
              <ProTradeSwitchRow>
                {switchTitles.map((item, index) => (
                  <SwitchRowItem
                    key={item}
                    active={index === activeTxTab}
                    onClick={() => setActiveTxTab(index)}
                  >
                    {item}
                  </SwitchRowItem>
                ))}
              </ProTradeSwitchRow>
            </div>
          </div>
          <div className="overflow-hidden">
            <div className={`h-full ${activeTxTab === 0 ? "block " : "hidden"}`}>
              <FavoriteTokenList active={activeTxTab === 0} isGraph={isGraph} />
            </div>
            <div className={`h-full ${activeTxTab === 1 ? "block" : "hidden"}`}>
              <FavoritePoolsList active={activeTxTab === 1} isGraph={isGraph} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FavoritesPoolsAndTokens;
