import { colors } from "@material-ui/core";
import React, { useState } from "react"
import { Link, useLocation } from "react-router-dom";

const HeaderLink = ({ href, text, dropdownLinks, active = false }) => {
    const location = useLocation()
    const [dropdownActive, setDropdownActive] = useState(false)
    return (
        <div
            onMouseEnter={() => {
                setDropdownActive(true)
            }}
            onMouseLeave={() => {
                setDropdownActive(false)
            }}
            onClick={() => {
                setDropdownActive(false)
            }}
            className={`group py-1 text-sm h-full relative `}
        >
            <Link to={href} className="">
                {href === '/earn' ?
                    <div className={`relative px-3 py-1 flex rounded-base transition-all duration-300 items-center group-hover:bg-black-80 group-hover:text-white ${dropdownActive ? "bg-black-80" : ""} h-full z-20
                        ${location.hash.includes('#Liquidity') ? 'text-white' : location.pathname.includes(href) ? 'text-white' : 'text-inactive-tab'}
                    `}>
                        {text}
                    </div>
                    :
                    <div className={`relative px-3 py-1 flex rounded-base transition-all duration-300 items-center group-hover:bg-black-80 group-hover:text-white ${dropdownActive ? "bg-black-80" : ""} h-full z-20
                        ${location.pathname.includes(href) && location.hash !== '#Liquidity' ? 'text-white' : 'text-inactive-tab'}
                    `}
                    >
                        {text}
                    </div>}
            </Link>
            {dropdownActive ? <>
                <div className="-ml-4 absolute top-full">
                    {dropdownLinks != null ?
                        <div style={{ border: "1px solid rgb(255 255 255 / 10%)" }} className="bg-theme-black rounded-md w-52">
                            {dropdownLinks.map((link) => (
                                <Link key={link.href} to={link.href} onClick={() => { setDropdownActive(false) }}>
                                    <div key={link.text} className="text-white hover:bg-black-80  hover:text-glow-low py-4 px-4">
                                        {link.text}
                                    </div>
                                </Link>
                            ))}
                        </div> : null}
                </div>
            </> : null}
        </div>
    )
}

export default HeaderLink
