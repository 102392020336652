

import React from "react";

const TrashBin = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99979 1.66675C8.53799 1.66675 7.32079 2.75901 7.11649 4.16675H2.70812C2.6253 4.16558 2.54307 4.18088 2.46622 4.21176C2.38936 4.24264 2.31941 4.2885 2.26043 4.34665C2.20145 4.4048 2.15462 4.4741 2.12265 4.55051C2.09068 4.62692 2.07422 4.70892 2.07422 4.79175C2.07422 4.87458 2.09068 4.95658 2.12265 5.03299C2.15462 5.1094 2.20145 5.1787 2.26043 5.23685C2.31941 5.295 2.38936 5.34085 2.46622 5.37173C2.54307 5.40262 2.6253 5.41792 2.70812 5.41675H3.59842L4.64822 16.2623C4.76239 17.4427 5.74388 18.3334 6.93013 18.3334H13.0694C14.2557 18.3334 15.2368 17.4427 15.3513 16.2623L16.4012 5.41675H17.2915C17.3743 5.41792 17.4565 5.40262 17.5334 5.37173C17.6102 5.34085 17.6802 5.295 17.7391 5.23685C17.7981 5.1787 17.845 5.1094 17.8769 5.03299C17.9089 4.95658 17.9254 4.87458 17.9254 4.79175C17.9254 4.70892 17.9089 4.62692 17.8769 4.55051C17.845 4.4741 17.7981 4.4048 17.7391 4.34665C17.6802 4.2885 17.6102 4.24264 17.5334 4.21176C17.4565 4.18088 17.3743 4.16558 17.2915 4.16675H12.8831C12.6788 2.75901 11.4616 1.66675 9.99979 1.66675ZM9.99979 2.91675C10.7828 2.91675 11.4251 3.44515 11.6087 4.16675H8.3909C8.57447 3.44515 9.21681 2.91675 9.99979 2.91675ZM8.12479 7.50008C8.46979 7.50008 8.74979 7.77966 8.74979 8.12508V14.3751C8.74979 14.7205 8.46979 15.0001 8.12479 15.0001C7.77979 15.0001 7.49979 14.7205 7.49979 14.3751V8.12508C7.49979 7.77966 7.77979 7.50008 8.12479 7.50008ZM11.8748 7.50008C12.2198 7.50008 12.4998 7.77966 12.4998 8.12508V14.3751C12.4998 14.7205 12.2198 15.0001 11.8748 15.0001C11.5298 15.0001 11.2498 14.7205 11.2498 14.3751V8.12508C11.2498 7.77966 11.5298 7.50008 11.8748 7.50008Z" fill="white" />
        </svg>
    );
};

export default TrashBin

