import React from "react"

const CaretRight = ({width = "32", height = "32", line = "2"}) => {
    return (
        <svg width={width} height={height} className={`wFull hFull`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 6L22 16L12 26" stroke="currentColor" strokeWidth={line} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default CaretRight
