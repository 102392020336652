import React, { ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import axios from 'rest/request';
import collectionsToLook from "../../../constants/nftCollections.json"

const NftContext = React.createContext<{
  collections: ICollection[],
  loading: boolean,
  tokens: any,
  tokenListCount: number,
  getTokens: (collectionsAddr?: string, limit?: number, market?: string, offset?: number) => any,
  getNextTokens: (collectionsAddr?: string, market?: string, priceStart?: string, priceFinish?: string, filter?: string, ASC?: string, from?:number, to?:number) => any,
  getCollections: () => void,
  getTokenInfo: (collectionsAddr: string, tokenId:string) => any,
  getTokenListing: (collectionAddress: string, tokenID: string, market?: string)=>  any
}>({
  collections: [],
  loading: true,
  tokens: [],
  tokenListCount: 0,
  getTokens: () => { },
  getCollections: () => { },
  getNextTokens: () => { },
  getTokenInfo: ()=>{},
  getTokenListing: ()=>{}
});

interface ITrait {
  type: string
  value: string
}

interface ICollectionInfo {
  name: string
  symbol: string
}

interface INFT {
  id: string
  name: string
  imageURL: string
  collection: ICollectionInfo
  traits: ITrait[]
}

interface IPageInfo {
  count?: number,
  hasNextPage?: boolean,
  hasPreviousPage?: boolean,
  offset?: number
}

interface ICollection extends ICollectionInfo {
  market: string
  authorLink: any;
  authorLogo: any;
  isVerifiedAutor: any;
  author: string;
  collectionAddr: any;
  collectionInfo: any;
  collectionName: string;
  items: INFT[]
}

interface IListing {
  token_id: string,
  collections_addr: string,
  market: string,
  order_id: string,
  created_at: number,
  expiration: number,
  price: number,
  denom: string,
  status: string
}

interface Props {
  children: ReactNode;
}

const convertIPFStoSrc = (link: string) => {
  return link.replace("ipfs://", "https://d1mx8bduarpf8s.cloudfront.net/")
}

const NFTContextProvider = ({ children }: Props) => {

  const [collections, setCollections] = useState<ICollection[]>([])
  const [tokens, setTokens] = useState([])
  const [pageInfo, setPageInfo] = useState({})
  const [collectionsMap, setcollectionsMap] = useState<{ [key: string]: ICollection }>({})
  const [loading, setLoading] = useState(true)
  const [tokenListCount, setTokenListCount] = useState(0)


  const getTokens = async (collectionsAddr = '', limit = 0, market = '', offset = 0) => {
    // const response = await axios.post('https://ipfs.tfm.dev/graphql', {
    //   query: `query MyQuery {
    //     token ${(collectionsAddr.length > 0 || market.length > 0) ? `(collectionsAddr: ${collectionsAddr}, limit: ${limit}, market: ${market}, offset: ${offset})` : `(limit: ${limit}, offset: ${offset})`} {
    //       pageInfo {
    //         count
    //         hasNextPage
    //         hasPreviousPage
    //         offset
    //       }
    //       tokens {
    //         activities
    //         bids
    //         collectionAddr
    //         createdAt
    //         imageUrl
    //         lastTradePrice
    //         listing
    //         listingTime
    //         market
    //         name
    //         price
    //         rarity
    //         tokenId
    //         tokenInfo
    //         traits
    //         updatedAt
    //       }
    //     }
    //   }
    // `
    // })
    // if (!response)
    //   return

    // setLoading(false)
    // const tokens = response.data.data?.token.tokens
    // return tokens
  }

  const getNextTokens = async (collectionsAddr = '', market = '', priceStart = '', priceFinish = '', filter = '', ASC = '', from?:number, to?:number) => {
    // try {
    //   const response = await axios.post('https://ipfs.tfm.dev/graphql', {
    //     query: `query MyQuery {
    //     token ${(collectionsAddr.length > 0 || market.length > 0) ?
    //         `(${collectionsAddr && `collectionsAddr: "${collectionsAddr}",`}
    //         limit: ${to ||( tokens.length + 24)},
    //        ${market && ` market: "${market}",`} 
    //         offset: ${from || tokens.length} ${(priceStart && priceFinish) && `, priceStart: ${priceStart}, priceFinish: ${priceFinish}`} ${filter && `, sorting:"${filter}"`} ${ASC && `, ASC: ${ASC}`})`
    //         :
    //         `(limit: ${to||(tokens.length + 24)}, offset: ${from ||tokens.length} ${(priceStart && priceFinish) && `, priceStart: ${priceStart}, priceFinish: ${priceFinish}`} ${filter && `, sorting:"${filter}"`} ${ASC && `, ASC: ${ASC}`})`} {
    //         pageInfo {
    //           count
    //           hasNextPage
    //           hasPreviousPage
    //           offset
    //         }
    //         tokens {
    //           collectionAddr
    //           createdAt
    //           imageUrl
    //           name
    //           price
    //           rarity
    //           tokenId
    //           listing
    //         }
    //     }
    //   }
    // `})
    //   if (response) {
    //     return response.data.data.token
    //   }
    // } catch (e) {
    //   console.warn(e.message)
    // }
  }

  const getTokenInfo = async (collectionsAddr:string, tokenId:string) => {
    // const response = await axios.post('https://ipfs.tfm.dev/graphql', {
    //   query: `query MyQuery {
    //     token (collectionsAddr: "${collectionsAddr}", tokenId: "${tokenId.toString()}") {
    //       tokens {
    //         activities
    //         bid
    //         collectionAddr
    //         createdAt
    //         imageUrl
    //         lastTradePrice
    //         listingTime
    //         name
    //         rarity
    //         price
    //         tokenId
    //         tokenInfo
    //         traits
    //         updatedAt
    //         market
    //       }
    //     }
    //   }
    // `})
    // if (!response)
    //   return

    // return response.data.data?.token.tokens
  }


   const getTokenListing = async (collectionAddress: string, tokenID: string) => {
    // const url = `https://nft.sspqf.org/lising/${collectionAddress}/${tokenID}`
    // const response = (await axios.get(url)).data
    // if (!response)
    //   return null
    // return response
  }

  const getCollections = async () => {
  //   const response = await axios.post('https://ipfs.tfm.dev/graphql', {
  //     query: `query MyQuery {
  //     collection{
  //       collectionAddr
  //       collectionInfo
  //       collectionName
  //       market
  //     }
  //   }
  // `
  //   })

  //   setCollections(response?.data?.data?.collection)
    // setcollectionsMap({ ...tempMap })

  }

  useEffect(() => {
    getCollections()
  }, [])

  useLayoutEffect(() => {
    if (collections?.length)
      setLoading(false)
  }, [collections, collectionsMap])

  return (
    <NftContext.Provider
      value={{
        collections,
        loading,
        tokens,
        getTokens,
        tokenListCount,
        getCollections,
        getNextTokens,
        getTokenInfo,
        getTokenListing
      }}
    >
      {children}
    </NftContext.Provider>
  );
};

export default NftContext;
export { NFTContextProvider };
