import React from "react"
import CaretRight from "../icons/caretRight"
import Loader from "../icons/loader"
import Checkmark from "../icons/checkmark"
import { useState } from "react";
import useTranslate from "../hooks/useTranslate"
import Checkbox from "../../platform/checkbox";
import googleTable from "helpers/googleTable"
import Button from "components/platform/button"
import Input from "components/platform/inputNew"
import SendEmail from "helpers/emailSending"

const SubscribeInput = ({ placeholder }) => {
    const [email, setEmail] = useState("")
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const { translated } = useTranslate('footer');
    const [errorMessage, setErrorMessage] = useState(translated("messageFail2"))
    const [success, setSuccess] = useState(false)
    const [isChecked, setIsChecked] = useState(false)

    const vaidateEmail = () => {
        return email.match(
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        )
    }
    return (
        <>
            <form
                onSubmit={async (e) => {
                    e.preventDefault();
                    const checkbox = document.getElementById("checkboxEmail") as HTMLInputElement
                    if (vaidateEmail() && checkbox.checked) {
                        setLoading(true)
                        setError(false)
                        SendEmail(email)
                        const submitRes: any = await googleTable(email)
                            setSuccess(true)
                            setLoading(false)

                    }
                    else {
                        setErrorMessage(translated("messageFail2"))
                        setError(true)
                    }
                }}
                className="w-full relative mt-4 overflow-hidden rounded-base">
                <div>
                    {error &&
                        <div className="mb-4 text-red text-xs-d">
                            {errorMessage}
                        </div>
                    }
                    <div className={`${success ? "opacity-0 pointer-events-none" : "opacity-100 "} transition-all flex items-center duration-1000 w-full`}>
                        <Input
                            classNameWrapper="w-full"
                            classNameInput={`w-full rounded-bl-base rounded-tl-base border-r-0 placeholder-opacity-30 transition-all duration-400 p-3 h-14 box-border outline-none bg-transparent text-base text-white`}
                            type={"text"}
                            minLength={1}
                            placeholder={placeholder}
                            value={email}
                            setValue={setEmail}
                        />
                        <Button type="submit" className="bg-action w-16 flex flex-col justify-center items-center rounded-bl-none rounded-tl-none rounded-tr-base rounded-br-base" >
                            <div className="w-8 h-8 text-theme-black">
                                {loading ? <Loader /> : <CaretRight />}
                            </div>
                        </Button>

                    </div>
                </div>
                <div className={`${success ? "opacity-100" : "opacity-0  pointer-events-none"} h-full top-0 bg-action transition-all absolute flex items-center justify-center duration-1000 w-full`}>
                    <div className="w-12 h-12 mr-2 "><Checkmark /></div>
                    <div className="text-black">{translated("messageSuccess")}</div>
                </div>
            </form>

            <div
                className="mt-3 flex items-center md:items-center">
                <Checkbox id="checkboxEmail" checked={isChecked} onChange={setIsChecked} />
                <div className="ml-3 md:text-sm text-theme-letter-light">
                    {"I have read the "}
                    <a href="./Terraformer_Privacy.pdf" target="_blank" className="underline transition-all hover:text-theme-blue">{"Privacy Policy"}</a>
                    {" and I give consent to TFM Corp for receiving commercial information"}
                </div>
            </div>
        </>
    )
}

export default SubscribeInput
