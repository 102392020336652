import CloseIcon from 'components/icons/close';
import SwapContext from 'components/platform/context/swap';
import Drawer, { TDrawerVariant } from 'components/platform/layout/drawer';
import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import FavoritesAndMyToken from './favoritesAndMyToken';
import UnderlineSwitch from './underlineSwitch'
import ChartIcon from './icons/chartIcon'
import SidePanelContext from '../platform/context/sidePanel'
import { useAddress } from '../../hooks'

const xl = 1280
const md = 768

const TokensDrawer = () => {
  const { activeTab, setActiveTab, graphState, setGraphState, tabs } = useContext(SidePanelContext)
  const address = useAddress()
  const { tokensOpen, setTokensOpen } = useContext(SwapContext)

  const [variant, setVariant] = useState<TDrawerVariant>("right")

  const handleResize = useCallback(() => {
    const html = document.querySelector("html")

    if (html.clientWidth >= md) {
      return setVariant("right")
    }

    return setVariant("bottom")
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (

      <Drawer open={tokensOpen} setOpen={setTokensOpen} variant={variant} isToken>
        {(drawerProps) => (
          <div className="flex h-full w-full items-end md:items-start md:justify-end text-white ">
            <div {...drawerProps} className="bg-theme-black w-full md:w-72 h-max-80-p md:h-max-full md:h-full grid-rows-max-fr box-shadow-tokens-drawer">
              <div>
                <div className="flex justify-between mb-2 mt-2 px-4">
                  <div className="flex items-center justify-between">
                    <UnderlineSwitch
                      switchItems={tabs}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  </div>
                  <div className="flex items-center">
                    <div className="flex items-center justify-center h-5 w-5 mr-4 transition-all hover:opacity-70 cursor-pointer" onClick={() => address ? setGraphState(!graphState) : () => { }}>
                      <ChartIcon fill={graphState ? '#5BFC85' : '#9B96B4'} />
                    </div>
                    <div
                      className="flex items-center justify-center h-5 w-5 transition-all text-white hover:text-theme-blue cursor-pointer"
                      onClick={() => setTokensOpen(false)}
                    >
                      <CloseIcon />
                    </div>
                  </div>

                </div>
                <div className="lg:pl-4 lg:pr-2">

              </div>
            </div>

              <div
                className={`h-full rounded-lg xl:py-4 xl:block grid-cols-2 gap-x-4 gap-y-3 blur-40 scrollbar-tune overflow-y-auto`}
              >
                <FavoritesAndMyToken />
              </div>
            </div>
        </div>
            )}
      </Drawer>
  );
}

export default TokensDrawer
