import React from "react"

interface props {
	width?: string
	height?: string
	color?: string
}

const Favorites: ({ width, height, color }: props) => JSX.Element = ({ width = "40", height = "40", color = "#262433" }: props) => {

	return (
		<svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="36" height="36" rx="8" fill="#383546" />
			<path d="M17.1875 10.9835C17.5139 10.2308 18.5813 10.2308 18.9077 10.9835L20.2844 14.1581C20.4202 14.4714 20.7156 14.686 21.0555 14.7184L24.5002 15.0467C25.3169 15.1245 25.6468 16.1397 25.0318 16.6827L22.4379 18.973C22.182 19.199 22.0691 19.5463 22.1434 19.8796L22.8957 23.257C23.074 24.0578 22.2104 24.6853 21.504 24.2682L18.5242 22.509C18.2302 22.3354 17.865 22.3354 17.571 22.509L14.5913 24.2682C13.8848 24.6853 13.0212 24.0578 13.1996 23.257L13.9519 19.8796C14.0261 19.5463 13.9133 19.199 13.6573 18.973L11.0635 16.6827C10.4485 16.1397 10.7783 15.1245 11.595 15.0467L15.0397 14.7184C15.3796 14.686 15.675 14.4714 15.8109 14.1581L17.1875 10.9835Z" stroke="white" strokeWidth="1.25" />
		</svg>


	)
}

export default Favorites
