import { ETradeUrlSearchParams } from "components/platform/context/proSwap";
import { useHistory, useLocation } from "react-router-dom";

const usePairSelect = () => {
  const location = useLocation()
  const history = useHistory()
  return (from: string, to: string) => {
    const params = new URLSearchParams(location.search);

    const token0 = params.get(ETradeUrlSearchParams.TOKEN0)
    const token1 = params.get(ETradeUrlSearchParams.TOKEN1)
    let isDifferent = false

    if (token0 !== from) {
      params.set(ETradeUrlSearchParams.TOKEN0, from)
      isDifferent = true
    }

    if (token1 !== to) {
      params.set(ETradeUrlSearchParams.TOKEN1, to)
      isDifferent = true
    }

    if (isDifferent)
      history.push({ pathname: location.pathname, hash: location.hash, search: '?' + params.toString() })
  }
}

export default usePairSelect;