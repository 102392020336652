// get environments
export const SECRET = process.env.REACT_APP_SECRET
export const SALT = process.env.REACT_APP_SALT
export const SERVERADDRESS = "https://api.tfm.com/api/"

/* terra:network */
export const FINDER = "https://finder.terra.money"
export const TERRA_STATION_EXTENSION =
  "https://chrome.google.com/webstore/detail/terra-station/aiifbnbfobpmeekipheeijimdpnlpgpp?hl=en"
export const XDEFI_EXTENSION = "https://chrome.google.com/webstore/detail/xdefi-wallet/hmeobnfnfcmdkdcmlblgagmfpfboieaf"
export const CHROME = "https://google.com/chrome"

/* terra:configs */
export const BLOCK_TIME = 6650 // 6.65s

/* terraswap:unit */
export const MIR = "MIR"
export const UUSD = "uusd"
export const ULUNA = "uluna"
export const UKRW = "ukrw"
export const USDR = "usdr"
export const UMNT = "umnt"
export const UAUD = "uaud"
export const UCAD = "ucad"
export const UCHF = "uchf"
export const UCNY = "ucny"
export const UEUR = "ueur"
export const UGBP = "ugbp"
export const UHKD = "uhkd"
export const UINR = "uinr"
export const UJPY = "ujpy"
export const USGD = "usgd"
export const UTHB = "uthb"
export const UST = "UST"
export const KRT = "KRT"
export const SDT = "SDT"
export const MNT = "MNT"
export const LUNA = "Luna"
export const AUT = "AUT"
export const CAT = "CAT"
export const CHT = "CHT"
export const CNT = "CNT"
export const EUT = "EUT"
export const GBT = "GBT"
export const HKT = "HKT"
export const INT = "INT"
export const JPT = "JPT"
export const SGT = "SGT"
export const THT = "THT"
export const LP = "LP"

export const NATIVE_TOKENS = [
  ULUNA,
  UUSD,
  UKRW,
  USDR,
  UMNT,
  UEUR,
  UCNY,
  UJPY,
  UGBP,
  UINR,
  UCAD,
  UCHF,
  UAUD,
  USGD,
  UTHB,
  UHKD,
]

/* terraswap:configs */
export const DEFAULT_MAX_SPREAD = 0.5
export const MAX_MSG_LENGTH = 1024

/* network:settings */
export const TX_POLLING_INTERVAL = 1000
export const MAX_TX_POLLING_RETRY = 20
export const DEFAULT_EXT_NETWORK: ExtNetworkConfig = {
  name: "mainnet",
  chainID: "columbus-5",
  fcd: process.env.REACT_APP_WEB3_TERRA_CLASSIC_FCD,
  lcd: process.env.REACT_APP_WEB3_TERRA_CLASSIC_LCD,
}

/* project */
export const MEDIUM = ""
export const DISCORD = ""
export const TELEGRAM = ""
export const WECHAT = ""
export const GITHUB = "https://github.com/DELIGHT-LABS/terraswap-web-app"

export const PROJECTSCONFIG = {
  bitlocus: {
    name: "Bitlocus",
    symbol: "BTL",
    supply: "500000000",
    contract: "terra1rytrl2xex84d9hzlrj96jv32t56cnk2w2n8u9v",
    userallocation: 250000000,
    regStart: 1638273600,
    regEnd: 1638360000,
    idoFinish: 1638414000,
    sheetLink: "https://docs.google.com/spreadsheets/d/1zb0zkXEHZJ6BK4VCob1_NUvMic5ED5Q9-h6NM8foPXk/edit?usp=sharing",
    decimals: 6,
    finish: true,
    vesting: true,
    earnamount: "100000000000",
  },
  defiato: {
    name: "Defiato",
    symbol: "DFIAT",
    supply: "250000000",
    contract: "terra1kcdz8uhfsgncgekg7htnrd58ykjrrjxc9h5v0r",
    userallocation: 500000000,
    regStart: 1645358400,
    regEnd: 1645531200,
    idoFinish: 1645563600,
    sheetLink: "https://docs.google.com/spreadsheets/d/12pWve29d-yOAnCJGrAh7q2mqbkQSS5Sg4ZUghdAnp7g/edit#gid=0",
    decimals: 8,
    finish: true,
    vesting: true,
    earnamount: "300000000000",
  },
  dragonsb: {
    name: "DragonSB",
    symbol: "SB",
    supply: "100000000",
    contract: "terra1ea0sxsm4tcn8cwzem2txhx96qrm2a0lv2fzsnu",
    userallocation: 250000000,
    regStart: 1645185600,
    regEnd: 1645358400,
    idoFinish: 1645390800,
    sheetLink: "https://docs.google.com/spreadsheets/d/1_7z3K1gii5bLGaPVOBg86i69Svn3Do5SX24YTG1oqZk/edit?usp=sharing",
    decimals: 6,
    finish: true,
    vesting: true,
    earnamount: "100000000000",
  },
  knightlands: {
    name: "Knightlands",
    symbol: "",
    supply: "",
    contract: "",
    userallocation: 0,
    regStart: 0,
    regEnd: 0,
    idoFinish: 0,
    sheetLink: "",
    decimals: 6,
    finish: false,
    vesting: false,
    earnamount: "100000000000",
  },
}
