
import React, { useEffect, useState } from "react";
import axios from "rest/request";

const USTPriceContext = React.createContext({
    ustPrice: null
});

const USTPriceContextProvider = ({ children }) => {
  const [ustPrice, setUstPrice] = useState(null);

  const getPrice = async ()=>{
     const response =  await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=terrausd&vs_currencies=usd')
     if (response)
     setUstPrice(response?.data.terrausd.usd)
  }
  useEffect(()=>{
      getPrice()
  },[])

  return (
    <USTPriceContext.Provider
      value={{
        ustPrice
      }}
    >
      {children}
    </USTPriceContext.Provider>
  );
};

export default USTPriceContext;

export {USTPriceContextProvider };
