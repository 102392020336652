import ScrollToTop from "layouts/ScrollToTop";
import React from "react";
import { useLocation } from "react-router-dom";
import Footer from "./layout/footer";
import Header from "./layout/header";

const Layout = ({ children }: { children: any }) => {
  const location = useLocation()

  return (
    <div className={`bg-noise ${location.pathname.includes("landing") ? "bg-theme-black" : "bg-black-80"} text-white h-full font-poppins text-base grid-rows-max-fr`}>
      <Header />
      <div className="grid-layout">
        <div className="grid-area-content w-full z-40" id='layout'>
          {children}
          <ScrollToTop />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Layout
