import React, { useState } from "react";
import MagnifyingGlassIcon from "./icons/magnifyingGlass";

const Input = ({
  value,
  setValue = (arg: any) => { },
  placeholder,
  autoFocus = false,
  type = "text",
  classNameWrapper = '',
  classNameInput = '',
  minLength = 1,
  searchInput = false,
  iconColor = "",
  onKeyDown = (arg: any) => { },
  customChange = false,
  onChange = (arg: any) => { }
}) => {

  const [focused, setFocused] = useState(false)

  return (
    <div className={`inputNew ${classNameWrapper} ${searchInput && 'flex items-center'}`}
    >
      {
        searchInput && <span className="absolute left-2 h-5"><MagnifyingGlassIcon strokeColor={iconColor} width="20" height="20" /></span>
      }
      <input
        className={`w-full text-base placeholder-black-50 font-poppins text-left border-px  
        ${focused ? 'border-input-active' : 'border-black-50'} 
        ${classNameInput}`}
        value={value}
        placeholder={placeholder}
        autoFocus={autoFocus}
        type={type}
        minLength={minLength}
        onChange={
          customChange ?
            onChange
            :
            ((e) => {
              setValue(e.target.value)
            })}
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => {
          setFocused(false)
        }}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}

export default Input