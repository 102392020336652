import React from "react"
const AnalyticsIcon = () => {
  return (
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24.5 22.75H3.5V5.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M24.5 10.5L17.5 16.625L10.5 11.375L3.5 17.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>

  )
}

export default AnalyticsIcon
