import React, { FC, useState } from "react"
import ReactModal from "react-modal"
import styles from "./Modal.module.scss"
import Icon from "./Icon"

ReactModal.setAppElement("#terraformer")

const Modal: FC<IModal> = ({
  className,
  classNameOverlay,
  isOpen,
  close,
  children,
  isCloseBtn = false,
  customClasses = false,
  zIndex = 'z-300',
}) => (
  <ReactModal
    className={customClasses ? className : `${styles.modal} ${className || ""}`}
    overlayClassName={`${styles.overlay + ' ' + zIndex} ${classNameOverlay || ""}`}
    isOpen={isOpen}
    onRequestClose={close}
  >
    {isCloseBtn && (
      <div className={styles.close}>
        <span onClick={close} className="text-white hover:text-theme-blue transition-all">
          <Icon name="close" size={30} />
        </span>
      </div>
    )}
    {children}
  </ReactModal>
)

export default Modal

/* modal */
export const useModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  return { isOpen, open: () => setIsOpen(true), close: () => setIsOpen(false) }
}
