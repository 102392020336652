import FavoriteContext from "components/platform/context/favorite";
import SwapContext from "components/platform/context/swap";
import USTPriceContext from "components/platform/context/USTprice";
import SkeletonLoading from "components/platform/layout/SkeletonLoading";
import formatNumbers from "helpers/formatNumbers";
import { getTokenIcon } from "helpers/token";
import TokenContext from "layouts/TokenContext";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Favorites from "./icons/favorites";
import TrashBin from "./icons/trashBin";




const FavMyTokensItem = ({
    address,
    amount,
    isGraph,
    children,
    index,
    price,
    addToken,
    removeToken,
    verify,
    ustPairAddress
}) => {
    const { assetsInfo } = useContext(TokenContext);
    const { setTokensOpen } = useContext(SwapContext)
    const { ustPrice } = useContext(USTPriceContext)
    const { tokens } = useContext(FavoriteContext);

    return (
        <div className={`group relative  items-center rounded-lg ${index === 0 ? "" : "mt-1"
            } border-px-black60 hover:bg-black-80 transition-all`} >
            <div className="relative mb-2 px-2 pt-2">
                <div>
                    <div className="grid-cols-max-fr mb-1">
                        <div className="flex items-center">
                            <div className="overflow-hidden p-px flex items-center justify-center pair-wrapper w-5 h-5 mr-1 ">
                                <img
                                    src={getTokenIcon(address)}
                                    alt=""
                                    className="w-full h-full rounded-full"
                                />
                            </div>
                            {assetsInfo && (
                                <Link to={`/analytics/tokens/${address}`} onClick={() => setTokensOpen(false)} className='group-hover:text-theme-blue'>
                                    <div className="flex">
                                        <span className="text-sm">
                                            {assetsInfo[address]?.symbol}
                                        </span>
                                    </div>
                                </Link>
                            )}
                        </div>

                    </div>
                </div>

                <div className="w-full flex">
                    <SkeletonLoading loading={!ustPrice}>
                        <div className="flex mr-2">
                            {amount}
                        </div>
                    </SkeletonLoading>
                    <div className="w-max" >
                        {price !== null
                            ? (
                                <SkeletonLoading loading={!ustPrice}>
                                    <span>
                                        <span className="opacity-70">$</span>{" "}
                                        {
                                            Number(formatNumbers((Number(amount) * Number(price) * ustPrice))).toFixed(2) == '0.00' ?
                                                '0.01'
                                                :
                                                Number(formatNumbers((Number(amount) * Number(price) * ustPrice))).toFixed(2)
                                        }
                                    </span>
                                </SkeletonLoading>
                            )
                            : "No data"}

                    </div>
                </div>
            </div>
            {children}
            {
                verify &&
                <div
                    className={` absolute top-0 right-0 flex rounded-lg transition-all duration-300 h-full px-3 h-min-8 opacity-0 group-hover:opacity-100 cursor-pointer items-center justify-center bg-black-80 blur-40`}

                >
                    <div className="h-8 w-8 ">
                        {tokens.includes(ustPairAddress) ? (
                            <div className="w-8 h-8 flex items-center justify-center bg-black-90 rounded-base"
                                onClick={(e) => removeToken(e, ustPairAddress)}
                            >
                                <TrashBin />
                            </div>
                        ) : (
                            <div className="w-8 h-8 flex items-center justify-center"
                                onClick={
                                    (e) => { addToken(e, ustPairAddress) }
                                }
                            >
                                <Favorites width="32" height="32" />
                            </div>
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

export default FavMyTokensItem
