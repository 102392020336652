import React from "react"

const MediumIcon = ({ prefix = "" }: { prefix?: string }) => {
  return (
    <svg className="hFull" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20ZM14.7431 27.3947C18.8966 27.3947 22.2638 23.9983 22.2638 19.8086C22.2638 15.6189 18.8969 12.2223 14.7431 12.2223C10.5894 12.2223 7.22221 15.6197 7.22221 19.8086C7.22221 23.9975 10.5897 27.3947 14.7431 27.3947ZM26.7542 26.9505C28.831 26.9505 30.5146 23.7523 30.5146 19.8086H30.5144C30.5144 15.8639 28.8308 12.6666 26.7539 12.6666C24.6771 12.6666 22.9937 15.8649 22.9937 19.8086C22.9937 23.7523 24.6773 26.9505 26.7542 26.9505ZM33.8884 19.8087C33.8884 23.3413 33.2963 26.2068 32.5658 26.2068C31.8353 26.2068 31.2435 23.342 31.2435 19.8087C31.2435 16.2754 31.8355 13.4106 32.5658 13.4106C33.2961 13.4106 33.8884 16.2751 33.8884 19.8087Z" fill="currentColor" />
    </svg>
  )
}

export default MediumIcon
