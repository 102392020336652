import React, { FC } from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import classNames from "classnames";
import { isNil } from "ramda";
import TooltipFavoritesIcon from './platform/icons/favorites'


export const DefaultTippyProps: TippyProps = {
	animation: false,
	interactive: false,
	appendTo: document.body,
};

const TooltipTippyProps: TippyProps = {
	...DefaultTippyProps,
	placement: "top",
	className:
		"bg-black-70 text-white rounded-base py-3 px-5 text-center text-xs-d shadow-tooltip font-poppins",
};

interface Props extends Omit<TippyProps, "children"> {
	onClick?: () => void;
	width?: string;
	height?: string;
}

const TooltipFavorites: FC<Props> = ({ className, width = '32', height = '32', onClick, children, ...props }) => {
	const button = (
		<button
			type="button"
			className={classNames(
				"flex opacity-50 hover:opacity-100 transition-all",
				className
			)}
			onClick={onClick}
		>
			{children}
		</button>
	)

	return props.content ? (
		<Tippy
			{...TooltipTippyProps}
			{...props}
			hideOnClick={isNil(props.visible) ? false : undefined}
		>
			{button}
		</Tippy>
	) : (
		button
	);
};

export const TooltipIconFavorites: FC<Props> = ({ children, ...props }) => (
	<div className="flex items-center">
		<div>
			<TooltipFavorites {...props}>
				<TooltipFavoritesIcon width={props.width} height={props.height} />
			</TooltipFavorites>
		</div>
		{children}
	</div>
);

export default TooltipFavorites;
