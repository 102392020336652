import React from "react";

const TelegramIcon = ({ prefix = "" }: { prefix?: string }) => {
  return (
    <svg className="h-full" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20ZM16.3334 29.1668L16.6737 24.0687L16.6734 24.0685L25.9475 15.6993C26.3545 15.3381 25.8586 15.1619 25.3183 15.4896L13.8728 22.7104L8.92898 21.1673C7.86133 20.8404 7.85367 20.1068 9.16868 19.5793L28.4337 12.1508C29.3136 11.7514 30.1628 12.3622 29.8269 13.7089L26.5461 29.1694C26.317 30.268 25.6532 30.5308 24.7334 30.0233L19.7357 26.3309L17.3334 28.6668C17.3258 28.6742 17.3183 28.6815 17.3108 28.6888C17.0421 28.9504 16.8198 29.1668 16.3334 29.1668Z" fill="currentColor" />
    </svg>
  )
}

export default TelegramIcon