import React, { useContext, useEffect } from "react";
import FavoritesPoolsAndTokens from "./favoritesPoolsAndTokens";
import NewMyTokens from "./newMyTokens";
import SidePanelContext from '../platform/context/sidePanel'
import UnderlineSwitch from './underlineSwitch'
import ChartIcon from './icons/chartIcon'
import CloseIcon from "components/platform/icons/close";
import { useAddress } from '../../hooks'

export default function FavoritesAndMyToken({ withHeader = false, close = () => { } }) {
    const address = useAddress();
    const { activeTab, graphState, tabs, setActiveTab, setGraphState, } = useContext(SidePanelContext)
    return (
        <>
            <div className={`h-full ${withHeader ? "pt-10 md:pt-8 xl:pt-0 grid-rows-max-fr" : ""}`}>
                {withHeader &&
                    <div
                        className={`xl:p-4 xl:block grid-cols-2 gap-x-4 gap-y-3 overflow-hidden blur-40`}
                    >
                        <div className="flex items-center justify-between mb-2">
                            <UnderlineSwitch
                                switchItems={tabs}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />
                            <div className="flex">
                                <div className="mr-4 cursor-pointer" onClick={() => address ? setGraphState(!graphState) : () => { }}>
                                    <ChartIcon fill={graphState ? '#5BFC85' : '#9B96B4'} />
                                </div>
                                <div
                                    onClick={close}
                                    className="flex w-max cursor-pointer hover:opacity-70 transition-all duration-300"
                                >
                                    <div className="hidden xl:block">
                                        <CloseIcon width="20" height="20" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="h-full overflow-hidden">
                    <div className={`${activeTab === 0 ? "block  h-full" : "hidden"}`}>
                        <FavoritesPoolsAndTokens isGraph={graphState} />
                    </div>
                    <div className={`${activeTab === 1 ? "block h-full overflow-y-auto scrollbar-tune" : "hidden"}`}>
                        <NewMyTokens isGraph={graphState} />
                    </div>
                </div>
            </div>
        </>
    );
}
