import React, {ReactNode, useState} from 'react'

const SidePanelContext = React.createContext({
	activeTab: 0,
	setActiveTab: (number: number) => {},
	graphState: false,
	setGraphState: (value: boolean) => {},
	tabs: [
		{
			label: "Favorites",
		},
		{
			label: "My Tokens",
		},
	]
})

interface Props {
	children: ReactNode;
}

const SidePanelContextProvider = ({children}: Props) => {
	const [activeTab, setActiveTab] = useState(0);
	const [graphState, setGraphState] = useState(false)
	const [tabs, setTabs] = useState([
		{label: "Favorites"},
		{label: "My Tokens"},
	])

	return (
		<SidePanelContext.Provider
			value={{
				activeTab,
				setActiveTab,
				graphState,
				setGraphState,
				tabs
			}}
		>
			{children}
		</SidePanelContext.Provider>
	)
}

export default SidePanelContext

export {SidePanelContextProvider as SidePanelContextProvider}
