import React from "react";

const LogoIcon = ({className = '', width = "55", height = "66"}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 55 66" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g filter="url(#filter0_dd_966:328)">
        <path d="M27.5 60.0549C39.3741 60.0549 49 50.441 49 38.5818C49 26.7225 39.3741 17.1086 27.5 17.1086C15.6259 17.1086 6 26.7225 6 38.5818C6 50.441 15.6259 60.0549 27.5 60.0549Z" fill="url(#paint0_linear_966:328)" />
        <path d="M27.5 60.0549C39.3741 60.0549 49 50.441 49 38.5818C49 26.7225 39.3741 17.1086 27.5 17.1086C15.6259 17.1086 6 26.7225 6 38.5818C6 50.441 15.6259 60.0549 27.5 60.0549Z" fill="url(#paint1_linear_966:328)" />
        <path d="M27.5 60.0549C39.3741 60.0549 49 50.441 49 38.5818C49 26.7225 39.3741 17.1086 27.5 17.1086C15.6259 17.1086 6 26.7225 6 38.5818C6 50.441 15.6259 60.0549 27.5 60.0549Z" fill="url(#paint2_linear_966:328)" />
        <path d="M27.5 60.0549C39.3741 60.0549 49 50.441 49 38.5818C49 26.7225 39.3741 17.1086 27.5 17.1086C15.6259 17.1086 6 26.7225 6 38.5818C6 50.441 15.6259 60.0549 27.5 60.0549Z" fill="url(#paint3_linear_966:328)" />
        <path d="M48.9998 38.4715C47.127 30.6773 40.6398 31.314 37.3189 44.3943C34.9827 53.5969 28.843 62.0663 18.0309 57.8798C11.7753 54.8123 7.23813 48.793 6.21484 41.6548C13.4442 44.8437 23.2367 46.7638 30.1873 37.4262C37.331 27.8185 43.4839 20.3335 47.1186 29.9281L47.5518 30.8124C48.4785 33.1854 48.9805 35.7706 48.9998 38.4715Z" fill="url(#paint4_linear_966:328)" />
        <path opacity="0.15" d="M9.54789 50.4335C7.51844 48.1369 6.34977 43.5266 6.13477 40.9735L14.7079 41.4573L33.2785 29.7129L43.4373 24.3379C44.575 25.3591 47.0278 28.1971 47.7373 31.3791C39.9166 15.496 22.206 64.7579 9.54789 50.4335Z" fill="url(#paint5_linear_966:328)" />
        <path d="M49 38.5465C49 50.4312 39.3685 60.0548 27.4979 60.0548C24.1008 60.0548 20.8967 59.2625 18.04 57.8518C28.849 62.0452 34.987 53.5617 37.3225 44.3439C40.6424 31.2418 47.1085 30.6041 49 38.4113C49 38.4692 49 38.5079 49 38.5465Z" fill="url(#paint6_linear_966:328)" />
        <path d="M49 38.5465C49 50.4312 39.3685 60.0548 27.4979 60.0548C24.1008 60.0548 20.8967 59.2625 18.04 57.8518C28.849 62.0452 34.987 53.5617 37.3225 44.3439C40.6424 31.2418 47.1085 30.6041 49 38.4113C49 38.4692 49 38.5079 49 38.5465Z" fill="url(#paint7_linear_966:328)" fillOpacity="0.2" />
        <mask id="mask0_966:328" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="29" y="31" width="20" height="27">
          <path d="M48.9729 38.4475C40.7222 28.2618 40.5879 50.8368 29.2197 57.3406L31.101 49.8156L37.4435 35.76L46.4197 31.8093L48.9729 34.1475V38.4475Z" fill="black" />
        </mask>
        <g mask="url(#mask0_966:328)">
          <path d="M48.9991 38.5465C48.9991 50.4312 39.3675 60.0548 27.4969 60.0548C24.0998 60.0548 20.8957 59.2625 18.0391 57.8518C28.848 62.0452 34.986 53.5617 37.3215 44.3439C40.6414 31.2418 47.1075 30.6041 48.9991 38.4113C48.9991 38.4692 48.9991 38.5079 48.9991 38.5465Z" fill="url(#paint8_linear_966:328)" />
          <path d="M48.9991 38.5465C48.9991 50.4312 39.3675 60.0548 27.4969 60.0548C24.0998 60.0548 20.8957 59.2625 18.0391 57.8518C28.848 62.0452 34.986 53.5617 37.3215 44.3439C40.6414 31.2418 47.1075 30.6041 48.9991 38.4113C48.9991 38.4692 48.9991 38.5079 48.9991 38.5465Z" fill="url(#paint9_linear_966:328)" fillOpacity="0.2" />
        </g>
        <g filter="url(#filter1_d_966:328)">
          <path d="M47.5487 30.798C39.9969 13.1579 18.685 68.1172 6.21545 41.636C5.63628 37.4898 6.00309 31.6466 10.1152 25.919C10.1152 25.919 14.652 41.9253 27.6061 32.2251C38.0504 24.3955 40.3671 23.7206 41.5061 23.2963L41.9887 22.6985C44.4212 24.9162 46.5812 27.4554 47.5487 30.798Z" fill="url(#paint10_linear_966:328)" />
        </g>
        <path d="M44.4855 25.171C36.0467 20.9785 11.8861 56.6416 7.37109 31.0298C7.88172 29.5516 8.74172 27.9391 9.92422 26.1385C10.8877 24.6715 25.8701 20.8352 33.2786 19.1241C34.7567 19.0346 39.2717 19.1241 44.4855 25.171Z" fill="url(#paint11_linear_966:328)" />
        <mask id="mask1_966:328" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="6" y="22" width="42" height="27">
          <path d="M47.5487 30.798C39.9969 13.1579 18.685 68.1172 6.21545 41.636C5.63628 37.4898 6.00309 31.6466 10.1152 25.919C10.1152 25.919 14.652 41.9253 27.6061 32.2251C38.0504 24.3955 40.3671 23.7206 41.5061 23.2963L41.9887 22.6985C44.4212 24.9162 46.5812 27.4554 47.5487 30.798Z" fill="url(#paint12_linear_966:328)" />
        </mask>
        <g mask="url(#mask1_966:328)">
          <g filter="url(#filter2_f_966:328)">
            <path d="M27.3118 38.9043L18.8193 45.2199L19.6256 48.9018L26.5056 44.0106L45.5868 27.5093L43.2756 25.4399L36.6912 29.1756L27.3118 38.9043Z" fill="url(#paint13_linear_966:328)" />
          </g>
        </g>
        <path d="M41.9336 22.7023L42.3352 23.0678C41.2177 23.4718 38.0222 24.3759 27.5982 32.1858C14.6886 41.8616 5.47854 33.8401 9.19726 27.319C11.2782 23.6641 15.1785 20.5286 18.6852 18.9897C18.6852 18.9897 18.5808 29.7235 28.1762 24.6067C30.3662 23.4361 36.7475 20.9518 39.1783 20.5479C40.1417 21.2019 41.0665 21.9136 41.9336 22.7023Z" fill="url(#paint14_linear_966:328)" />
        <path d="M13.6866 22.1343C14.7616 21.2743 16.5622 19.8231 18.6316 19.0437C18.6316 19.4307 18.9899 21.2654 19.1691 22.1343H29.3547C32.0243 21.2295 37.4118 19.4576 37.6053 19.6081C37.8472 19.7962 39.5672 20.7368 40.6422 21.6237C35.4822 22.1343 10.9453 36.8349 13.6866 22.1343Z" fill="url(#paint15_linear_966:328)" />
        <mask id="mask2_966:328" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="8" y="18" width="35" height="19">
          <path d="M41.9336 22.7023L42.3352 23.0678C41.2177 23.4718 38.0222 24.3759 27.5982 32.1858C14.6886 41.8616 5.47854 33.8401 9.19726 27.319C11.2782 23.6641 15.1785 20.5286 18.6852 18.9897C18.6852 18.9897 18.5808 29.7235 28.1762 24.6067C30.3662 23.4361 36.7475 20.9518 39.1783 20.5479C40.1417 21.2019 41.0665 21.9136 41.9336 22.7023Z" fill="url(#paint16_linear_966:328)" />
        </mask>
        <g mask="url(#mask2_966:328)">
          <g filter="url(#filter3_f_966:328)">
            <path d="M29.9993 27.0792L14.1162 34.1474L16.0243 36.7005H26.8281L36.8525 28.9336L45.9631 22.0805L44.6193 21.5161L38.1962 22.9136L29.9993 27.0792Z" fill="url(#paint17_linear_966:328)" />
          </g>
        </g>
        <path d="M39.1531 20.573L39.8084 21.0157C37.3799 21.2467 30.3837 23.9027 27.8975 25.0383C18.0103 29.5612 13.0571 24.9998 17.7019 19.9572C18.1837 19.4375 18.627 19.0141 18.627 19.0141C21.306 17.7823 24.2934 17.1086 27.4542 17.1086C31.7714 17.1086 35.7802 18.3789 39.1531 20.573Z" fill="url(#paint18_linear_966:328)" />
        <mask id="mask3_966:328" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="15" y="1" width="25" height="26">
          <path d="M39.1531 20.573L39.8084 21.0157C37.3799 21.2467 30.3837 23.9027 27.8975 25.0382C18.0103 29.5612 13.0571 24.9998 17.7019 19.9571C18.1837 19.4375 18.627 19.014 18.627 19.014C9.46649 -1.59638 28.0207 1.11798 31.1815 1.11798C35.4987 1.11798 35.7802 18.3789 39.1531 20.573Z" fill="url(#paint19_linear_966:328)" />
        </mask>
        <g mask="url(#mask3_966:328)">
          <g filter="url(#filter4_f_966:328)">
            <ellipse cx="27.4192" cy="27.133" rx="10.4544" ry="7.01437" fill="#298942" />
          </g>
          <path d="M19.3373 7.83448L19.3291 12.1334C19.3291 12.1334 19.362 12.8953 19.7069 13.2402C20.06 13.585 19.9615 7.83448 19.9615 7.83448L19.403 7.2891C19.4112 7.28108 19.3784 7.44149 19.3373 7.83448Z" fill="#339966" />
          <path d="M30.9396 12.4487V32.39C30.4673 32.3021 30.003 32.1903 29.5467 32.0546C28.57 31.7751 27.6254 31.4157 26.7208 30.9764C26.3285 30.7848 25.9363 30.5771 25.56 30.3535C25.3119 30.2098 25.0637 30.058 24.8235 29.8983C24.6234 29.7705 24.4313 29.6348 24.2392 29.491C23.943 29.2754 23.6468 29.0518 23.3586 28.8122C23.1184 28.6205 22.8863 28.4129 22.6621 28.2053V12.4487H30.9396Z" fill="#5CDA7D" />
          <path d="M30.0793 12.4487V32.2287C30.0146 32.2127 29.95 32.1968 29.8854 32.1808C29.8612 32.1488 29.837 32.1168 29.8127 32.0848C29.627 31.805 29.4493 31.4452 29.2797 31.0055C29.207 30.8136 29.1343 30.6057 29.0616 30.3819C29.0132 30.2379 28.9647 30.086 28.9243 29.9261C28.8839 29.7982 28.8516 29.6623 28.8113 29.5184C28.7547 29.3025 28.6982 29.0786 28.6497 28.8388C28.6093 28.6469 28.5609 28.439 28.5205 28.2312V12.4487H30.0793Z" fill="#9AFDB4" />
          <path d="M24.7584 13.9557V30.3476C24.5765 30.2119 24.4025 30.0763 24.2286 29.9246C24.0863 29.8049 23.9439 29.6852 23.8095 29.5496C23.596 29.3501 23.3825 29.1426 23.177 28.9271C23.003 28.7515 22.837 28.56 22.6788 28.3764V16.5972C22.5128 10.3804 23.6118 13.9557 24.7584 13.9557Z" fill="#339966" />
          <path d="M34.2716 8.12989V11.7643C34.2716 13.0743 33.2233 14.1607 31.903 14.1687C31.895 14.1687 31.887 14.1687 31.879 14.1687H21.6681C21.6601 14.1687 21.6521 14.1687 21.6441 14.1687C20.3317 14.1607 19.2834 13.4018 19.2754 11.7643V8.12989C19.2754 7.25923 19.3874 6.70807 20.4277 5.97319L21.0199 5.8374H31.951C33.2153 5.8374 34.2556 6.84386 34.2636 8.10593C34.2716 8.11392 34.2716 8.12191 34.2716 8.12989Z" fill="#5CDA7D" />
          <path d="M27.499 13.255H29.9068L30.5128 12.407C31.0461 11.663 31.143 10.6951 30.7633 9.8711L30.4724 9.23113C30.2462 8.73514 30.0926 8.20716 30.028 7.67119L29.9068 6.75122H27.499V7.51119C27.499 8.15917 27.9273 8.73514 28.5575 8.91914C28.5656 8.91914 28.5736 8.92714 28.5817 8.92714C28.9453 9.03913 29.2281 9.32712 29.3251 9.69511L29.4301 10.1111C29.4705 10.2631 29.5432 10.7111 29.5998 10.8551C29.7937 11.335 29.0665 11.663 28.6544 12.087L27.499 13.255Z" fill="#9AFDB4" />
          <path d="M31.9526 21.2252L31.5137 21.7772C31.2662 22.0892 31.2662 22.5213 31.5137 22.8333L31.9526 23.3853L31.4977 23.9613C31.2662 24.2573 31.2662 24.6813 31.4977 24.9773L31.9526 25.5534L31.4259 26.2174C31.2343 26.4654 31.2343 26.8094 31.4259 27.0574L31.9526 27.7214L31.4578 28.3535C31.2503 28.6175 31.2503 28.9935 31.4578 29.2575L31.9526 29.8895L31.4498 30.5295C31.2423 30.7855 31.2423 31.1536 31.4498 31.4096L31.9526 32.0496L31.5935 32.4976C31.362 32.4656 31.1385 32.4256 30.9151 32.3776C30.4442 32.2896 29.9812 32.1776 29.5263 32.0416C28.5526 31.7616 27.6107 31.4016 26.7088 30.9616C26.3177 30.7695 25.9267 30.5615 25.5515 30.3375C25.3041 30.1935 25.0567 30.0415 24.8172 29.8815C24.6177 29.7535 24.4261 29.6175 24.2346 29.4735C23.9393 29.2575 23.644 29.0335 23.3646 28.7935C23.1252 28.6015 22.8937 28.3935 22.6702 28.1855C22.5026 28.0335 22.335 27.8734 22.1754 27.7054C22.0716 27.6014 21.9758 27.5054 21.8801 27.4014L22.4946 26.6254L21.6406 25.5454L22.4946 24.4653L21.6406 23.3853L22.4946 22.3053L21.6406 21.2252L22.4946 20.1452L21.6406 19.0651C22.1834 18.3771 23.0055 17.9851 23.8754 17.9851H30.2606C30.7953 17.9851 31.2982 18.2331 31.6334 18.6491L31.9606 19.0651L31.5296 19.6092C31.2822 19.9292 31.2822 20.3692 31.5296 20.6892L31.9526 21.2252Z" fill="#5CDA7D" />
          <path d="M24.7581 20.1623L23.9093 21.2429L24.7581 22.3236L23.9093 23.4042L24.7581 24.4848L23.9093 25.5654L24.7581 26.646L23.9093 27.7266L24.7581 28.8072L24.2266 29.4876C23.9331 29.2715 23.6396 29.0474 23.3541 28.8072C23.1161 28.6151 22.886 28.407 22.6639 28.1989C22.4973 28.0468 22.3308 27.8867 22.1721 27.7186C22.069 27.6145 21.9738 27.5185 21.8786 27.4144L22.1166 27.1103C22.3308 26.8301 22.3308 26.4459 22.1166 26.1657L21.6406 25.5574L22.1086 24.957C22.3308 24.6769 22.3308 24.2767 22.1086 23.9885L21.6406 23.3882L22.1166 22.7798C22.3308 22.4997 22.3308 22.1154 22.1166 21.8353L21.6406 21.2269L22.1166 20.6186C22.3308 20.3384 22.3308 19.9542 22.1166 19.6741L21.6406 19.0657C22.18 18.3773 22.9971 17.9851 23.8617 17.9851H24.7581L23.9093 19.0657L24.7581 20.1623Z" fill="#339966" />
          <path d="M31.9606 19.0601L31.6331 19.4741C31.2977 19.8962 30.7944 20.1351 30.2593 20.1351H22.4953L21.6406 19.0601H31.9606Z" fill="#122C1C" />
          <path d="M31.9606 21.2637L31.9207 21.3348C31.5616 21.9514 30.8991 22.3387 30.1728 22.3387H22.4946L21.6406 21.2716H31.9606V21.2637Z" fill="#122C1C" />
          <path d="M31.9606 23.4138L31.109 24.4888H22.4923L21.6406 23.4138H31.9606Z" fill="#122C1C" />
          <path d="M31.8532 25.5637L31.0016 26.6387H22.3849L21.5332 25.5637H31.8532Z" fill="#122C1C" />
          <path d="M19.2834 7.14946L24.3816 6.80493V13.3991H20.1558C19.6516 13.7917 19.2754 12.4376 19.2754 12.1572L19.2834 7.14946Z" fill="#339966" />
          <path d="M31.1588 13.2549H24.2207V6.53613H31.855C32.7752 6.53613 33.5195 7.28177 33.5195 8.20379V10.8977C33.5115 12.1966 32.4551 13.2549 31.1588 13.2549Z" fill="#5CDA7D" />
          <path d="M34.2717 7.82631H19.5337H19.2217C19.2217 5.30753 20.9499 5.63228 21.726 5.6402L31.9274 5.67981C32.7915 5.67981 34.0236 6.03624 34.2717 7.82631Z" fill="#122C1C" />
          <path opacity="0.67" d="M33.912 6.76564C33.3201 5.41801 31.8802 5.70508 30.6803 5.68116C28.3206 5.63331 27.1367 5.65723 24.7689 5.65723C21.0333 5.65723 20.2433 5.40763 19.5934 6.31909C18.9436 7.23055 19.3564 10.0301 19.2757 12.1264C19.2917 13.0354 19.9476 13.5776 19.9476 13.5776C19.9476 13.5776 28.2006 9.55659 34.272 8.0734C34.248 7.53913 34.184 7.37965 33.912 6.76564Z" fill="#3F3E43" />
          <path d="M31.5307 7.82617H29.6494V14.1687H31.5307V7.82617Z" fill="#9AFDB4" />
          <path d="M33.7886 13.2549C33.1722 13.9482 32.82 14.3299 31.5313 14.3299H21.9097C20.4928 14.3299 20.2687 13.9482 19.6523 13.2549H33.7886Z" fill="#122C1C" />
        </g>
        <mask id="mask4_966:328" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="18" y="33" width="31" height="28">
          <path d="M49 38.5465C49 50.4312 39.3685 60.0548 27.4979 60.0548C24.1008 60.0548 20.8967 59.2625 18.04 57.8518C28.849 62.0452 34.987 53.5617 37.3225 44.3439C40.6424 31.2418 47.1085 30.6041 49 38.4113C49 38.4692 49 38.5079 49 38.5465Z" fill="url(#paint20_linear_966:328)" />
          <path d="M49 38.5465C49 50.4312 39.3685 60.0548 27.4979 60.0548C24.1008 60.0548 20.8967 59.2625 18.04 57.8518C28.849 62.0452 34.987 53.5617 37.3225 44.3439C40.6424 31.2418 47.1085 30.6041 49 38.4113C49 38.4692 49 38.5079 49 38.5465Z" fill="url(#paint21_linear_966:328)" fillOpacity="0.2" />
        </mask>
        <g mask="url(#mask4_966:328)">
          <path opacity="0.06" d="M37.6319 44.4942C33.4394 58.7917 25.1619 60.9685 18.0938 57.9317L20.405 55.5398L26.4787 50.2185L35.2669 38.9042L39.755 31.7017L48.9731 32.3198C49.7794 36.6556 50.5453 44.0657 48.9731 38.4473C47.1456 31.9167 41.8244 30.1967 37.6319 44.4942Z" fill="black" />
        </g>
        <mask id="mask5_966:328" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="6" y="25" width="43" height="34">
          <path d="M48.9998 38.4715C47.127 30.6773 40.6398 31.314 37.3189 44.3943C34.9827 53.5969 28.843 62.0663 18.0309 57.8798C11.7753 54.8123 7.23813 48.793 6.21484 41.6548C13.4442 44.8437 23.2367 46.7638 30.1873 37.4262C37.331 27.8185 43.4839 20.3335 47.1186 29.9281L47.5518 30.8124C48.4785 33.1854 48.9805 35.7706 48.9998 38.4715Z" fill="url(#paint22_linear_966:328)" />
        </mask>
        <g mask="url(#mask5_966:328)">
          <g filter="url(#filter5_f_966:328)">
            <path d="M34.4879 43.1236L30.9404 55.7011H34.4879L39.5404 48.7136L43.0879 36.9692H48.9736L46.0979 31.9167H41.1798L34.4879 43.1236Z" fill="url(#paint23_linear_966:328)" />
          </g>
        </g>
      </g>
      <defs>
        <filter id="filter0_dd_966:328" x="0.625" y="-4.16" width="53.75" height="69.8047" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.215" />
          <feGaussianBlur stdDeviation="2.6875" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.352941 0 0 0 0 1 0 0 0 0 0.52549 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_966:328" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.215" />
          <feGaussianBlur stdDeviation="0.1075" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.352941 0 0 0 0 1 0 0 0 0 0.52549 0 0 0 1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_966:328" result="effect2_dropShadow_966:328" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_966:328" result="shape" />
        </filter>
        <filter id="filter1_d_966:328" x="6" y="22.6985" width="41.5488" height="26.6621" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.37625" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_966:328" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_966:328" result="shape" />
        </filter>
        <filter id="filter2_f_966:328" x="13.4443" y="20.0649" width="37.5176" height="34.2119" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.6875" result="effect1_foregroundBlur_966:328" />
        </filter>
        <filter id="filter3_f_966:328" x="8.74121" y="16.1411" width="42.5967" height="25.9343" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.6875" result="effect1_foregroundBlur_966:328" />
        </filter>
        <filter id="filter4_f_966:328" x="12.7186" y="15.8724" width="29.4017" height="22.5213" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.12312" result="effect1_foregroundBlur_966:328" />
        </filter>
        <filter id="filter5_f_966:328" x="25.5654" y="26.5417" width="28.7832" height="34.5344" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="2.6875" result="effect1_foregroundBlur_966:328" />
        </filter>
        <linearGradient id="paint0_linear_966:328" x1="23.2932" y1="23.0868" x2="31.2181" y2="52.3944" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5BFC85" />
          <stop offset="0.098061" stopColor="#63E171" />
          <stop offset="0.27" stopColor="#6FB854" />
          <stop offset="0.4382" stopColor="#79983C" />
          <stop offset="0.5996" stopColor="#7F812C" />
          <stop offset="0.7511" stopColor="#847321" />
          <stop offset="0.8828" stopColor="#856E1E" />
        </linearGradient>
        <linearGradient id="paint1_linear_966:328" x1="23.2932" y1="23.0868" x2="31.2181" y2="52.3944" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5BFC85" />
          <stop offset="0.098061" stopColor="#63E171" />
          <stop offset="0.27" stopColor="#6FB854" />
          <stop offset="0.4382" stopColor="#79983C" />
          <stop offset="0.5996" stopColor="#7F812C" />
          <stop offset="0.7511" stopColor="#847321" />
          <stop offset="0.8828" stopColor="#856E1E" />
        </linearGradient>
        <linearGradient id="paint2_linear_966:328" x1="23.2932" y1="23.0868" x2="31.2181" y2="52.3944" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5BFC85" />
          <stop offset="0.098061" stopColor="#63E171" />
          <stop offset="0.27" stopColor="#6FB854" />
          <stop offset="0.4382" stopColor="#79983C" />
          <stop offset="0.5996" stopColor="#7F812C" />
          <stop offset="0.7511" stopColor="#847321" />
          <stop offset="0.8828" stopColor="#856E1E" />
        </linearGradient>
        <linearGradient id="paint3_linear_966:328" x1="23.2932" y1="23.0868" x2="31.2181" y2="52.3944" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5BFC85" />
          <stop offset="0.098061" stopColor="#63E171" />
          <stop offset="0.27" stopColor="#6FB854" />
          <stop offset="0.4382" stopColor="#79983C" />
          <stop offset="0.5996" stopColor="#7F812C" />
          <stop offset="0.7511" stopColor="#847321" />
          <stop offset="0.8828" stopColor="#856E1E" />
        </linearGradient>
        <linearGradient id="paint4_linear_966:328" x1="40.4805" y1="30.7612" x2="24.9069" y2="58.2085" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CFAE48" />
          <stop offset="0.485083" stopColor="#86712E" />
          <stop offset="1" stopColor="#584818" />
        </linearGradient>
        <linearGradient id="paint5_linear_966:328" x1="12.9341" y1="49.1973" x2="44.8885" y2="27.8585" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint6_linear_966:328" x1="41.905" y1="40.1404" x2="31.9684" y2="59.6435" gradientUnits="userSpaceOnUse">
          <stop stopColor="#745D18" />
          <stop offset="1" stopColor="#3E3210" />
        </linearGradient>
        <linearGradient id="paint7_linear_966:328" x1="26.9894" y1="57.0986" x2="33.52" y2="60.0548" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint8_linear_966:328" x1="41.4472" y1="35.9748" x2="46.3911" y2="39.094" gradientUnits="userSpaceOnUse">
          <stop stopColor="#645219" />
          <stop offset="0.0001" stopColor="#645219" />
          <stop offset="1" stopColor="#3E3210" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint9_linear_966:328" x1="26.9884" y1="57.0986" x2="33.5191" y2="60.0548" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint10_linear_966:328" x1="31.1007" y1="24.7874" x2="15.0275" y2="49.9517" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D9D952" />
          <stop offset="0.0276529" stopColor="#D4D350" />
          <stop offset="0.2407" stopColor="#B1AA3F" />
          <stop offset="0.4492" stopColor="#968A32" />
          <stop offset="0.6491" stopColor="#837329" />
          <stop offset="0.8369" stopColor="#776523" />
          <stop offset="1" stopColor="#736021" />
        </linearGradient>
        <linearGradient id="paint11_linear_966:328" x1="6.80672" y1="33.9054" x2="38.2505" y2="23.9616" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7C6B26" />
          <stop offset="1" stopColor="#80FFA2" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint12_linear_966:328" x1="31.1007" y1="24.7874" x2="15.0275" y2="49.9517" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D9D952" />
          <stop offset="0.0276529" stopColor="#D4D350" />
          <stop offset="0.2407" stopColor="#B1AA3F" />
          <stop offset="0.4492" stopColor="#968A32" />
          <stop offset="0.6491" stopColor="#837329" />
          <stop offset="0.8369" stopColor="#776523" />
          <stop offset="1" stopColor="#736021" />
        </linearGradient>
        <linearGradient id="paint13_linear_966:328" x1="19.3837" y1="43.2849" x2="39.54" y2="26.1118" gradientUnits="userSpaceOnUse">
          <stop stopColor="#796828" />
          <stop offset="1" stopColor="#F8F885" />
        </linearGradient>
        <linearGradient id="paint14_linear_966:328" x1="23.6616" y1="39.9925" x2="25.1938" y2="27.2754" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8BC60" />
          <stop offset="0.0490445" stopColor="#CDBF62" />
          <stop offset="0.2646" stopColor="#A2CD6A" />
          <stop offset="0.4731" stopColor="#7FD871" />
          <stop offset="0.6701" stopColor="#67E075" />
          <stop offset="0.8508" stopColor="#58E478" />
          <stop offset="1" stopColor="#53E679" />
        </linearGradient>
        <linearGradient id="paint15_linear_966:328" x1="19.4916" y1="25.1443" x2="33.5203" y2="20.4949" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3AD864" />
          <stop offset="1" stopColor="#3AD864" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint16_linear_966:328" x1="23.6616" y1="39.9925" x2="25.1938" y2="27.2754" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D8BC60" />
          <stop offset="0.0490445" stopColor="#CDBF62" />
          <stop offset="0.2646" stopColor="#A2CD6A" />
          <stop offset="0.4731" stopColor="#7FD871" />
          <stop offset="0.6701" stopColor="#67E075" />
          <stop offset="0.8508" stopColor="#58E478" />
          <stop offset="1" stopColor="#53E679" />
        </linearGradient>
        <linearGradient id="paint17_linear_966:328" x1="43.1412" y1="22.1342" x2="21.7218" y2="34.3892" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2FFD1" />
          <stop offset="1" stopColor="#88EEA1" />
        </linearGradient>
        <linearGradient id="paint18_linear_966:328" x1="28.6635" y1="18.2256" x2="26.6384" y2="28.0948" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5BFC85" />
          <stop offset="1" stopColor="#5BFC85" />
        </linearGradient>
        <linearGradient id="paint19_linear_966:328" x1="28.6635" y1="18.2256" x2="26.6384" y2="28.0948" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5BFC85" />
          <stop offset="1" stopColor="#5BFC85" />
        </linearGradient>
        <linearGradient id="paint20_linear_966:328" x1="41.905" y1="40.1404" x2="31.9684" y2="59.6435" gradientUnits="userSpaceOnUse">
          <stop stopColor="#745D18" />
          <stop offset="1" stopColor="#3E3210" />
        </linearGradient>
        <linearGradient id="paint21_linear_966:328" x1="26.9894" y1="57.0986" x2="33.52" y2="60.0548" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="paint22_linear_966:328" x1="31.0184" y1="31.1091" x2="22.7771" y2="52.7727" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B69736" />
          <stop offset="0.0576265" stopColor="#AE9033" />
          <stop offset="0.3151" stopColor="#8E752A" />
          <stop offset="0.5636" stopColor="#776223" />
          <stop offset="0.7971" stopColor="#69561E" />
          <stop offset="1" stopColor="#64521D" />
        </linearGradient>
        <linearGradient id="paint23_linear_966:328" x1="45.4261" y1="31.8899" x2="33.2517" y2="55.7011" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E0C05C" />
          <stop offset="1" stopColor="#7A6526" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default LogoIcon
