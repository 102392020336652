import { getTokenIcon } from "helpers/token";
import React from "react";

const PairImages = ({ primary, secondary, variant = "md" }: { primary, secondary: string, variant?: "md" | "lg" }) => {
  return (
    <div className="mr-1 flex items-center h-max my-auto">
      <div className={`overflow-hidden p-px flex items-center justify-center pair-wrapper ${variant === "md" ? "w-5 h-5" : "w-6 h-6"} z-10`}>
        <img
          src={getTokenIcon(primary)}
          alt=""
          className="w-full h-full rounded-full"
        />
      </div>
      <div className={`overflow-hidden p-px flex items-center justify-center pair-wrapper w-4 h-4 -translate-x-25p mb-auto`}>
        <img
          src={getTokenIcon(secondary)}
          alt=""
          className="w-full h-full rounded-full"
        />
      </div>
    </div>
  )
}
export default PairImages