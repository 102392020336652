import React, { useContext, useEffect, useLayoutEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import Search from './search'
import Favorites from '../icons/favorites'
import { useAddress } from '../../../hooks'
import { TooltipIconFavorites } from '../../TooltipFavorites'
import { useModal } from '../../Modal'
import BlockchainDropdown from "components/protrade/blockchainDropdown"
import NavBarLink from "./NavBarLink"
import SwapContext from "../context/swap"
import TokensDrawer from "components/protrade/tokensDrawer"

type TabListType = {
  label: string,
  href: string,
  regex?: RegExp,
  className?: string
}[]

interface NavbarProps {
  tabList: TabListType;
  withSearch?: boolean;
  landing?: boolean;
  withPlatformAndFavorites?: boolean;
  gridStyle?: string;
  liquidity?: boolean;
  favorites?: boolean
}

const Navbar = ({
  tabList,
  withSearch = false,
  landing = false,
  withPlatformAndFavorites = false,
  gridStyle = "",
  liquidity = false,
  favorites = false,
}: NavbarProps) => {
  const location = useLocation();
  const wallet = useAddress();
  const favoritesModal = useModal();
  const { tokensOpen, setTokensOpen } = useContext(SwapContext)


  return (
    <div className={`flex navbar h-full md:h-14 bg-theme-black overflow-visible  relative z-full shadow-inset-bottom ${(location.hash === "#Liquidity" && !liquidity) ? `hidden` : 'flex'}`}>
      <div className={`md:px-12 w-full ${gridStyle}`}>
        <div
          className={`col-span-full flex flex-wrap md:flex-nowrap items-center p-0 md:py-2
           ${(!withSearch && !withPlatformAndFavorites && !landing) ? "justify-center" : "justify-between"}
           ${landing ? "justify-start" : ""}`}
        >
          <div className="flex justify-center items-center w-full md:w-auto">
            {tabList && tabList.map((item) => (
              <NavBarLink
                {...item}
              />
            ))}
          </div>

          {(withSearch || withPlatformAndFavorites) && (
            <div
              className={`relative shadow-inset-top md:noBorder w-max-6xl md:ml-2 w-full p-4 md:p-0 flex ${!withPlatformAndFavorites ? 'md:justify-end' : 'md:justify-between'}`}
            >
              <Search />
              <div className={`h-10 ${favorites ? 'mx-3' : 'ml-8'} `}>
                <BlockchainDropdown />
              </div>
              {favorites && (
                <div className="hover:opacity-70 h-10 transition-all duration-300 cursor-pointer">
                  {!wallet
                    ?
                    <div onClick={() => setTokensOpen(!tokensOpen)}>
                      <TooltipIconFavorites width="40" height="40" content="Connect your wallet to add to favorites" />
                    </div>
                    :
                    <div onClick={() => setTokensOpen(!tokensOpen)}>
                      <Favorites width="40" height="40" />
                    </div>
                  }
                  <TokensDrawer />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Navbar;
