import React from 'react'

const WalletIcon = ({className = ''}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.4987 5.83341C18.4192 5.83341 19.1654 6.57961 19.1654 7.50008V9.16675H15.832C14.4513 9.16675 13.332 10.286 13.332 11.6667C13.332 13.0475 14.4513 14.1667 15.832 14.1667H19.1654V15.8334C19.1654 16.7539 18.4192 17.5001 17.4987 17.5001H2.4987C1.57822 17.5001 0.832031 16.7539 0.832031 15.8334V4.58341C0.832031 3.66294 1.57822 2.91675 2.4987 2.91675H14.1654C15.0858 2.91675 15.832 3.66294 15.832 4.58341H3.54036C3.19519 4.58341 2.91536 4.86324 2.91536 5.20841C2.91536 5.55359 3.19519 5.83341 3.54036 5.83341H17.4987ZM15.8327 10.4167C15.1423 10.4167 14.5827 10.9764 14.5827 11.6667C14.5827 12.3571 15.1423 12.9167 15.8327 12.9167H19.166V10.4167H15.8327Z" fill="white" />
    </svg>
  )
}

export default WalletIcon