import React, { useEffect, useContext, useState, useRef, useLayoutEffect, MutableRefObject } from "react";
import FavoriteContext from "components/platform/context/favorite";
import { getTokenIcon } from "helpers/token";
import axios from "axios";
import TokenContext from "layouts/TokenContext";
import { Link } from "react-router-dom";
import { Line, LineChart, ResponsiveContainer } from "recharts";
import ChevronRight from "./icons/ChevronRight";
import FavoritesChosen from "./icons/favoritesChosen";
import Favorites from "./icons/favorites";
import { useInView } from "react-intersection-observer";
import formatNumbers from '../../helpers/formatNumbers'
import SwapContext from '../platform/context/swap'
import PriceGraph from "./priceGraph";
import USTPriceContext from "components/platform/context/USTprice";
import SkeletonLoading from "components/platform/layout/SkeletonLoading";
import TrashBin from "./icons/trashBin";
import FavTokenCard from "./FavTokenCard";
import usePairSelect from "hooks/usePairSelect";
import ProSwapContext from "components/platform/context/proSwap";
import Accordion from "components/platform/accordion";

const idPrefix = "favorite-token:"

const FavoriteTokenList = ({ isGraph, active }) => {
  const { tokens, tokensHandler, removeToken, addToken } = useContext(FavoriteContext);
  const { setTokensOpen } = useContext(SwapContext)
  const { activePair } = useContext(ProSwapContext)
  const { assetsInfo } = useContext(TokenContext);
  const [tokensData, setTokensData] = useState([]);
  const [mountedIndex, setMountedIndex] = useState(0)
  const { ustPrice } = useContext(USTPriceContext)
  const [prevLen, setPrevLev] = useState(0)
  const handlePairSelect = usePairSelect()



  async function getData() {
    const tokensString = tokens.join(",")

    const tokensRes = (
      await axios.post(process.env.REACT_APP_ANALYTICS_GQL_API, {
        query: `
				query FavoriteTokensList {
				  period(contractAddrList: "${tokensString}",token0: "uusd") {
					content {
					  contractAddr
					  token0Address
					  token1Address
					  priceInverted
					  diff1
					  diff24
					  diff168
					  absoluteVolume24
					  verify
					  liquidity
					  pairPriceWeek
            price
            market
					}
					pageInfo {
					  asc
					  count
					  limit
					  skip
					  sortField
					}
				  }
				}
				`,
      })
    ).data.data.period.content;

    setTokensData([...tokensRes]);
  }

  useEffect(() => {
    if ((prevLen < tokens.length) && tokens.length > 0) {
      getData();
      setPrevLev(tokens.length)
    }
  }, [tokens]);

  const [displayGraph, setDisplayGraph] = useState<Record<string, boolean>>({})

  const containterRef = useRef() as MutableRefObject<HTMLDivElement>
  const intersectionObserver = useRef() as MutableRefObject<IntersectionObserver>

  const onObserve = (entries: IntersectionObserverEntry[]) => {
    let temp = {}

    entries.forEach((entrie) => {
      if (entrie.isIntersecting) {
        entrie.target.setAttribute("loaded", "true")
        temp[entrie.target.getAttribute("data-address")] = true
        intersectionObserver.current.unobserve(entrie.target)
      }
    })

    setDisplayGraph((prev) => ({ ...prev, ...temp }))
  }

  useLayoutEffect(() => {
    if (!active) {
      setDisplayGraph({})
      setMountedIndex(0)
      containterRef.current && containterRef.current.scrollTo(0, 0)
      intersectionObserver.current && intersectionObserver.current.disconnect()
    }

    if (!tokensData.length || !containterRef.current)
      return

    const elemetns = containterRef.current.querySelectorAll(`[id*="${idPrefix}"]`)

    if (!intersectionObserver.current)
      intersectionObserver.current = new IntersectionObserver(onObserve, { root: containterRef.current })

    elemetns.forEach((element) => {
      intersectionObserver.current.observe(element)
    })

  }, [tokensData, active])

  useLayoutEffect(() => {
    if (active && containterRef.current) {
      containterRef.current.scrollTo({ top: 0, behavior: "auto" })
    }
  }, [active])

  useLayoutEffect(() => {
    if (!isGraph)
      setMountedIndex(0)
  }, [isGraph])

  return (
    <div
      ref={containterRef}
      className={`mt-2 pb-2 scrollbar-tune overflow-y-auto h-full xxxl:max-h-572 px-3`}
    >
      {tokensData.length > 0 ? (
        <>
          {tokensData.map((item, index) => (
            <div className="cursor-pointer"
              onClick={() => { handlePairSelect(item.token1Address, activePair.secondary) }}
            >
              <FavTokenCard
                isGraph={isGraph}
                {...item}
                removeToken={removeToken}
                index={index}
                id={idPrefix + item.contractAddr}
                dataAddress={item.contractAddr}
                addToken={addToken}
              >
                {item.pairPriceWeek &&
                  <Accordion open={isGraph}>
                    <div className="h-10">
                      <PriceGraph data={item.pairPriceWeek} index={index} />
                    </div>
                  </Accordion>
                }

              </FavTokenCard>
            </div>


          ))}
        </>
      ) : (
        <>
          <div
            className="col-span-1 px-2 flex justify-center items-center"
            style={{ height: "60px" }}
          >
            <p className="ml-2 opacity-70">Your tokens list is empty</p>
          </div>
        </>
      )}
    </div>
  );
};

export default FavoriteTokenList;
