import React from "react";
import CheckIcon from "./icons/Check";

const Checkbox = ({ checked, onChange, className = "", id = "" }: { checked: boolean, onChange: (cheked: boolean) => void, className?: string, id?: string }) => {

  return (
    <div className={`container-checkbox ${className}`} onClick={() => onChange(!checked)}>
      <input id={id} checked={checked} onChange={(e) => onChange(e.target.checked)} type="checkbox" />
      <div>
        <div>
          <CheckIcon />
        </div>
      </div>
    </div>
  );
}

export default Checkbox