import React, { useState } from "react";

const NFTFilterContext = React.createContext({
  openFilter: false,
  setOpenFilter: (arg) => { },
  visible: true,
  setVisible: (arg) => { }
});

const NFTFilterContextProvider = ({ children }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [visible, setVisible] = useState(true);

  return (
    <NFTFilterContext.Provider
      value={{
        openFilter,
        setOpenFilter,
        visible,
        setVisible
      }}
    >
      {children}
    </NFTFilterContext.Provider>
  );
};

export default NFTFilterContext;

export { NFTFilterContextProvider };