import React from "react";

const DiscordIcon = ({ prefix = "" }: { prefix?: string }) => {
  return (
    <svg className="h-full" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20ZM23.2176 10.9093C23.2176 10.9093 25.99 10.8476 28.9041 13.0683C28.9041 13.0683 31.8182 18.4352 31.8182 25.0562C31.8182 25.0562 30.0982 28.0378 25.6259 28.1817C25.6259 28.1817 24.8974 27.2976 24.2901 26.5163C26.9412 25.7556 27.9531 24.0693 27.9531 24.0693C27.1234 24.6245 26.334 25.0153 25.6259 25.2825C21.2657 27.1434 16.4139 26.5067 12.9982 24.5628C12.9883 24.5562 12.9526 24.5353 12.8998 24.5045C12.7327 24.407 12.3948 24.2097 12.1685 24.0489C12.1685 24.0489 13.1397 25.6937 15.7099 26.4751C15.1028 27.2567 14.3539 28.1818 14.3539 28.1818C9.8818 28.0378 8.18182 25.0563 8.18182 25.0563C8.18182 18.4352 11.0959 13.0683 11.0959 13.0683C14.01 10.8476 16.7825 10.9093 16.7825 10.9093L16.9848 11.1561C13.3421 12.2253 11.6624 13.8498 11.6624 13.8498C11.6624 13.8498 12.1076 13.603 12.8564 13.2535C17.5194 11.1723 23.1661 11.1727 27.9733 13.8498C27.9733 13.8498 26.3745 12.3075 22.9343 11.2383L23.2176 10.9093ZM14.1515 20.8615C14.1515 22.116 15.0825 23.1441 16.2158 23.1441C17.3692 23.1441 18.2799 22.116 18.2799 20.8615C18.3002 19.6072 17.3692 18.5791 16.2158 18.5791C15.0622 18.5791 14.1515 19.6072 14.1515 20.8615ZM21.538 20.8615C21.538 22.116 22.469 23.1441 23.6021 23.1441C24.7557 23.1441 25.6664 22.116 25.6664 20.8615C25.6664 19.6072 24.7557 18.5791 23.6021 18.5791C22.4487 18.5791 21.538 19.6072 21.538 20.8615Z" fill="currentColor" />
    </svg>
  )
}

export default DiscordIcon
