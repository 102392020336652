import { NetworkInfo } from "@terra-dev/wallet-types"

export enum NetworkKey {
  MAINNET = "mainnet",
  TESTNET = "testnet",
}
type MirrorNetworkInfo = NetworkInfo & NetworkConfig

const networks: Record<string, MirrorNetworkInfo> = {
  mainnet: {
    name: "mainnet",
    chainID: "columbus-5",
    lcd: process.env.REACT_APP_WEB3_TERRA_CLASSIC_LCD,
    fcd: process.env.REACT_APP_WEB3_TERRA_CLASSIC_FCD,
    id: "columbus-5",
    contract: "/tequila.json",
    swap: "/swap.json",
    mantle: process.env.REACT_APP_WEB3_TERRA_CLASSIC_FCD,
    stats: process.env.REACT_APP_WEB3_TERRA_CLASSIC_FCD,
    fee: { gasPrice: "0.00506", amount: "1518", gas: "2000000" }, // 0.000500 UST
    factory: "terra1u27ypputx3pu865luzs4fpjsj4llsnzf9qeq2p",
    service: process.env.REACT_APP_MAINNET_SERVICE_URL || "https://api.terraswap.io/",
    router: "terra15gmwayskpzzcv35gvr6z3h9887xdrxmgcw9f75",
  },
  testnet: {
    name: "testnet",
    chainID: "bombay-12",
    lcd: "https://bombay-lcd.terra.dev",
    fcd: "https://bombay-fcd.terra.dev",
    id: "bombay-12",
    contract: "/tequila.json",
    swap: "/swap.json",
    mantle: "https://bombay-mantle.terra.dev/",
    stats: "https://bombay-fcd.terra.dev/",
    fee: { gasPrice: "0.00506", amount: "1518", gas: "2000000" }, // 0.050000 UST
    factory: "terra1d2ewkn2wj4sg4z8jpzrz5naqp3s5rhtkkv7gaf",
    service: process.env.REACT_APP_TESTNET_SERVICE_URL || "https://api-bombay.terraswap.io/",
    router: "terra1f6np5vlv5y0gss8yfmjhh7u6r4v6d08pvw88y0",
  },
}

export default networks
