import LogoIcon from "../icons/logo";
import Container from "./container";
import Button from "../button";
import React, { useState, useLayoutEffect, useContext } from "react";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { useAddress, useConnectModal } from "hooks";
import WalletButton from "../walletButton";
import HeaderBackground from "./headerBackground";
import HeaderLink from "./headerLink";
import CloseIcon from "../icons/close";
import SwapChartContext from "../context/swapChart";
import WalletDropdown from "../walletDropdown";
import Navbar from "components/platform/layout/Navbar";
import ThemeContext from "../context/themeContext";

const navItems = [
  {
    text: "Trade",
    href: "/trade/protrade",
    // dropdownLinks: [
    //   {
    //     text: "Swap",
    //     href: "/trade#Swap"
    //   },
    //   {
    //     text: "Liquidity",
    //     href: "/trade#Liquidity"
    //   },
    //   // {
    //   //   text: "Multiswap",
    //   //   href: "/trade#Multiswap"
    //   // },
    // ]
  },
  {
    text: "Analytics",
    href: "/analytics",
  },
  {
    text: "Earn",
    href: "/earn",
    dropdownLinks: [
      {
        text: "Pools",
        href: "/earn#Pools",
      },
      {
        text: "Farms",
        href: "/earn#Farms",
      },
      {
        text: "Liquidity",
        href: "/trade#Liquidity",
      },
    ],
  },
  {
    text: "Launch",
    href: "/launchpad",
  },
  // {
  //   text: "NFT",
  //   href: "/nft",
  // },
  // {
  //   text: "IDO",
  //   href: "/idos",
  // },
];

const SwapTabList = [
  // {
  //   label: "Stats",
  //   href: "/trade/stats",
  //   regex: /\/trade\/stats/,
  // },
  {
    label: "Pro Swap",
    href: "/trade/protrade",
    regex: /\/trade\/protrade/,
  },
  {
    label: "Swap",
    href: "/trade",
    regex: /\/trade((\/swap)|$|\/$)/,
    className: "hidden lg:inline"
  },
];

const AnalyticsTabList = [
  {
    label: "Overview",
    href: "/analytics",
    regex: /\/analytics((\/overview)|$|\/$)/,
  },
  {
    label: "Pairs",
    href: "/analytics/pairs",
    regex: /\/analytics\/pairs/,
  },
  {
    label: "Tokens",
    href: "/analytics/tokens",
    regex: /\/analytics\/tokens/,
  },
  {
    label: "Transactions",
    href: "/analytics/transactions",
    regex: /\/analytics\/transactions/,
  },
];

const EarnTabList = [
  {
    label: "Farms",
    href: "/earn#Farms",
    regex: /\/earn#Farms/,
  },
  {
    label: "Pools",
    href: "/earn#Pools",
    regex: /\/earn#Pools/,
  },
  {
    label: "Liquidity",
    href: "/trade#Liquidity",
    regex: /\/trade#Liquidity/,
  },
];

const LandingTabList = [
  {
    label: "Home",
    href: "/landing/home",
    regex: /\/home/,
  },
  {
    label: "Memorandum",
    href: "/landing/memorandum",
    regex: /\/landing\/memorandum/,
  },
  {
    label: "Token",
    href: "/landing/token",
    regex: /\/landing\/token/,
  },
];

const Header = () => {
  const connectModal = useConnectModal();
  const address = useAddress();
  const handleClick = () => connectModal.open();
  let { path } = useRouteMatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { theme, setTheme } = useContext(ThemeContext);

  useLayoutEffect(() => {
    let phishingStatus = localStorage.getItem("phishingStatus") === "true";
    if (!phishingStatus) {
      setOpen(true);
      localStorage.setItem("phishingStatus", `${false}`);
    }
  }, []);

  const onClick = () => {
    localStorage.setItem("phishingStatus", `${true}`);
    setOpen(false);
  };

  return (
    <div
      id="header"
      className={`z-full sticky top-0 isolate w-full right-0 left-0`}
    >
      <div
        className={`w-full bg-card  ${open ? "shadow-popup" : "h-0-px"
          } transition-all duration-300 overflow-hidden`}
      >
        <Container>
          <div className="col-span-full grid grid-cols-fr-max items-center">
            <div className="lg:text-center text-sm lg:text-base py-3">
              PHISHING WARNING: Make sure you're visiting{" "}
              <a
                href="https://tfm.com"
                className="font-bold underline hover: transition-all"
              >
                https://tfm.com
              </a>{" "}
              — check the URL carefully
            </div>
            <div className="flex" onClick={onClick}>
              <CloseIcon />
            </div>
          </div>
        </Container>
      </div>
      <div className="w-full flex justify-between py-2 lg:py-3 px-3 md:px-12 shadow-inset-bottom">
        <div className="flex">
          <div className="flex items-center">
            <Link to={"/landing/home"} className="flex items-center pr-6">
              <div className="flex items-center mr-1">
                <LogoIcon width="16" height="20" />
              </div>
              <div className="text-lg font-centauri sm:block">TFM</div>
            </Link>
          </div>

          <div className="hidden lg:flex items-center mx-auto justify-center w-full z-full">
            {navItems.map((item) => (
              <div className="h-full z-20" key={item.text}>
                <HeaderLink
                  text={item.text}
                  href={item.href}
                  dropdownLinks={item.dropdownLinks}
                  active={
                    item?.dropdownLinks?.find(
                      (item) => item.text === "Liquidity"
                    ) !== undefined
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center z-5">
          {!address ? (
            <Button
              paddingOff
              className="block px-4 py-2 text-sm"
              onClick={handleClick}
            >
              Connect Wallet
            </Button>
          ) : (
            <WalletDropdown />
          )}
        </div>
      </div>
      <div className="-z-1 isolate">
        <Switch>
          <Route path={`/trade`}>
            {location.hash === "Liquidity" ? (
              <Navbar
                tabList={EarnTabList}
                gridStyle="proSwap-container"
                liquidity
              />
            ) : (
              <Navbar
                tabList={SwapTabList}
                gridStyle="proSwap-container"
                withSearch
                favorites
              />
            )}
          </Route>
          <Route path={`/landing`}>
            <Navbar tabList={LandingTabList} gridStyle="proSwap-container" landing />
          </Route>
          <Route path={`/analytics`}>
            <Navbar
              tabList={AnalyticsTabList}
              gridStyle="analytics-container"
              withSearch
              favorites
            />
          </Route>
          <Route path={`/earn`}>
            <Navbar tabList={EarnTabList} gridStyle="proSwap-container" />
          </Route>
        </Switch>
      </div>
      <HeaderBackground />
    </div>
  );
};

export default Header;
