
import React, { useState, useContext, useLayoutEffect, useEffect, useCallback, useRef, MutableRefObject } from "react";
import EarnIcon from "../icons/earn";
import LaunchIcon from "../icons/launch";
import TradeIcon from "../icons/trade";
import { Link, useLocation } from 'react-router-dom';
import AgreementPopup from "../agreementPopup";
import DotsIcon from "../icons/dots";
import ArrowDownIcon from "../icons/arrowDown";
import FilterButtonBar from "../NFT/filter/filterButtonBar";
import CreatedButtonBar from "../NFT/myNFT/createdButtonBar";
import MenuContext from "components/platform/context/menu";
import useWindowSize from "components/landing/hooks/useWindowSize";
import Analytics from "../icons/analytics";


const points: { text: string, href: string, icon: any }[] = [
  {
    text: "Trade",
    href: "/trade/protrade",
    icon: <TradeIcon />
  },
  {
    text: "Analytics",
    href: "/analytics",
    icon: <Analytics />
  },
  {
    text: "Earn",
    href: "/earn",
    icon: <EarnIcon />
  },
  {
    text: "Launch",
    href: "/launchpad",
    icon: <LaunchIcon />
  },
]
const extraPoints: { text: string, href: string, dropdownLinks: boolean }[] = [
  {
    text: "KYC",
    href: "/kyc",
    dropdownLinks: false
  }
]


const MobileMenu = ({ children }: { children: any }) => {
  const location = useLocation()
  const [extraOpen, setExtraOpen] = useState(false)
  const { isMobileMenuHidden, setIsMoblieMenuHidden } = useContext(MenuContext)
  const [isMobile, setIsMobile] = useState(false)
  const [prevHeight, setPrevHeight] = useState(0)

  const screenSize = useWindowSize()
  const getMobile = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }


  useLayoutEffect(() => {
    setIsMobile(getMobile())
    setPrevHeight(screenSize.height)
    console.log(`${performance.now() / 1000} mobile menu mounted`)
  }, [])

  useEffect(() => {
    screenSize.height - prevHeight > 0 && setIsMoblieMenuHidden(false)
    setPrevHeight(screenSize.height)
  }, [screenSize])

  return (
    <div className="relative h-full">
      <div className="h-full"
        onClick={() => {
          if (extraOpen) setExtraOpen(false)
        }}
      >
        {children}
      </div>
      <div className={`${isMobile && isMobileMenuHidden ? 'translate-y-full' : 'translate-y-0'} transition-all fixed h-full grid-rows-fr-max isolate bottom-0 pointer-events-none w-full z-50`}>
        <div className="isolate relative">
          <div id="bottomDrawer" className="absolute w-full h-full">

          </div>
          <div className="text-white p-0 md:pr-6 md:pb-6 flex justify-end items-end h-full z-full">
            <AgreementPopup />
          </div>
        </div>
        <div className="relative z-full isolate">
          <div className="grid-container bg-theme-black relative">
            <div id="mobile-menu"
              className="flex col-span-full pointer-events-auto lg:hidden text-white bg-theme-black pt-1 pb-1 justify-between items-baseline w-full relative z-50 text-xss">
              {points.map((item, index) =>
                <Link
                  onClick={() => setExtraOpen(false)}
                  key={item.text}
                  to={item.href}
                  className={`transition-all py-3 duration-100 flex flex-col items-center cursor-pointer hover:text-theme-blue ${location.pathname.includes(item.href) ? 'text-theme-blue' : ""}`}
                >
                  <div>
                    {item.icon}
                  </div>
                  <div className="mt-1">
                    {item.text}
                  </div>
                </Link>
              )}
              <div onClick={() => setExtraOpen(!extraOpen)} className={`hover:text-theme-blue ${extraOpen ? "text-theme-blue" : ""} transition-all`}>
                <DotsIcon />
              </div>
            </div>
            <div className={`absolute top-0 w-full pointer-events-auto z-10 transition-all ${extraOpen ? "-translate-y-full block" : "translate-y-0 hidden"}`}>
              <FilterButtonBar />
              <CreatedButtonBar />
              {extraPoints.map((item) =>

                !item.dropdownLinks ?

                  <Link key={item.text} onClick={() => setExtraOpen(false)} to={item.href} className="shadow-bottom-px bg-theme-black text-white block shadow-bottom-px py-3 px-4 text-left w-full text-base">
                    {item.text}
                  </Link>
                  :
                  <div key={item.text} className="flex items-center justify-between shadow-bottom-px bg-theme-black text-white block shadow-bottom-px py-3 px-4 text-center w-full text-base">
                    <div>{item.text}</div>
                    <ArrowDownIcon color={"#5BFC85"} />
                  </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu
