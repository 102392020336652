import { Coin, Coins, CreateTxOptions, LCDClient, Msg, StdFee } from '@terra-money/terra.js';
import axios from 'axios';
import { createGetPairAddressQuery } from 'helpers/getPairAddress';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useAddress, useNetwork } from 'hooks';
import { useWallet } from '@terra-money/wallet-provider';
import { LimitOrder, NativeTokenAssetInfo, OrderAsset, TokenAssetInfo } from '../interfaces';
import tokensDictionary from "constants/tokensDictionary.json"

export enum ESwapType {
  SWAP = "swap",
  LIMIT = "limit"
}

const LimitContext = React.createContext({
  lcdclient: undefined,
  swapType: ESwapType.SWAP,
  setSwapType: (arg: ESwapType) => { },
  price: "0",
  setPrice: (arg: string) => { },
  getPrice: async (token0: string, token1: string) => "0",
  getOrderDetails: async (orderId) => { return undefined },
  getOrders: async (userAddress) => { return undefined },
  cancelOrder: async (orderID, onSuccess = (result: any) => { }, onError = (error: any) => { }) => { },
  cancelAllOrders: async (orders, onSuccess = (result: any) => { }, onError = (error: any) => { }) => { },
  submitOrder: async (offer: TokenAsset, ask: TokenAsset, onSuccess = (result: any) => { }, onError = (error: any) => { }) => { },
  orders: [],
  setOrders: (arg: LimitOrder[]) => { },
  ordersLoading: true,
  setOrdersLoading: (arg: boolean) => { },
  fetchOrders: () => { }
});

export type TokenAsset = {
  address: string,
  amount: string
}

interface Props {
  children: ReactNode;
}

export const LimitTabs = [
  {
    label: "Active Orders"
  },
  {
    label: "Order History"
  },
]

const isNative = (asset) => {
  return !!asset?.info?.native_token
}

export const getAssetAddress = (asset: OrderAsset) => {
  if (!asset)
    return undefined

  if ((asset as NativeTokenAssetInfo).info.native_token)
    return (asset as NativeTokenAssetInfo).info.native_token.denom

  return (asset as TokenAssetInfo).info.token.contract_addr
}

export const LIMIT_ORDER_CONTRACT_ADDRESS = "terra1r5s87x880hg2updcsw2wephxkn5eg7cfcfrzal"
// const USER_ALLOCATION = 0
const maxAmoutPerCall = 30

// utility
export const TransformMessageArray = (msgs) => {

  return msgs.map((data: Msg.Data | Msg.Data[]) => {
    return (Array.isArray(data) ? data : [data])
      .map((item: Msg.Data) => Msg.fromData(item))
  }).map((msg: any) => {
    return Array.isArray(msg) ? msg[0] : msg
  })
}


const LimitContextProvider = ({ children }: Props) => {
  const [lcdclient, setLcdclient] = useState<LCDClient>()

  const [swapType, setSwapType] = useState<ESwapType>(ESwapType.SWAP)

  const [orders, setOrders] = useState<LimitOrder[]>([])
  const [ordersLoading, setOrdersLoading] = useState(true)

  const [price, setPrice] = useState("0")

  const [pairInfo, setPairInfo] = useState<{ [key: string]: { isNative: boolean, address: string } }>();

  const { fee, chainID } = useNetwork()
  const { post: terraExtensionPost, network } = useWallet()
  const fcd = network["fcd"]
  const walletAddress = useAddress()

  const getPrice = async (token0, token1) => {
    const response = await axios.get(`${process.env.REACT_APP_ROUTING_API_ROOT}/route?token0=${token0}&token1=${token1}&amount=${1 * (10 ** (tokensDictionary[token0]?.decimals ?? 6))}&use_split=true`)

    if (response.status !== 200)
      return "0"

    const price = response.data?.return_amount ?? 0

    return price.toFixed(tokensDictionary[token0]?.decimals ?? 6)
  }

  // lcd requesst
  const getOrderDetails = async (orderId) => {
    if (!lcdclient)
      return

    return await lcdclient.wasm.contractQuery(
      LIMIT_ORDER_CONTRACT_ADDRESS,
      { order: { order_id: orderId } } // query msg
    );

  }

  // lcd requesst
  const getOrders = async (userAddress, page = 0) => {
    if (!lcdclient)
      return

    return await lcdclient.wasm.contractQuery(
      LIMIT_ORDER_CONTRACT_ADDRESS,
      {
        orders: {
          bidder_addr: userAddress,
          limit: maxAmoutPerCall,
          start_after: maxAmoutPerCall * page,
          order_by: "asc"
        }
      } // query msg
    );
  }

  const fetchOrders = async () => {
    if (!walletAddress || !lcdclient) {
      setOrders([])
      setOrdersLoading(false)
      return
    }

    setOrdersLoading(true)

    const result1: { orders: LimitOrder[] } = await getOrders(walletAddress) as { orders: LimitOrder[] }
    const result2: { orders: LimitOrder[] } = await getOrders(walletAddress, 1) as { orders: LimitOrder[] }

    setOrders([...result1.orders, ...result2.orders].sort((a, b) => b.order_date - a.order_date))
    setOrdersLoading(false)
  }

  // utility
  const makeTokenInfo = (address, amount) => {
    if (!pairInfo)
      throw new Error(`No data about pair`)

    if (!pairInfo[address])
      throw new Error(`No data about token ${address}`)

    if (pairInfo[address].isNative)
      return {
        info: {
          native_token: {
            denom: address
          }
        },
        amount
      }

    return {
      info: {
        token: {
          contract_addr: address
        },
      },
      amount
    }
  }

  // utility
  const estimateFees = async (amount: number, gas: number) => {
    const [gasPricesResult, taxRateResult, taxCapResult] = await Promise.all([
      axios.get(fcd + '/v1/txs/gas_prices'),
      axios.get(fcd + '/treasury/tax_rate'),
      axios.get(fcd + '/treasury/tax_cap/uusd'),
    ]).catch(() => {
      throw new Error("Error: can't get fees information");
    });

    const gasPrices = gasPricesResult.data;
    const taxRate = parseFloat(taxRateResult.data.result);
    const taxCap = parseInt(taxCapResult.data.result);

    const gasFees = gas * gasPrices['uusd'];
    const taxFees = Math.min(Math.ceil(amount * taxRate), taxCap);

    return gasFees + taxFees;
  }



  // utility
  const getFee = async (offer?: TokenAsset) => {
    const gas = fee.gas

    let feess = offer
      ? await estimateFees(pairInfo[offer.address].isNative ? parseFloat(offer.amount) : 0, gas)
      : await estimateFees(0, gas)

    const feeCoins = new Coins({})
    feeCoins.set("uusd", feess)


    const gasPrices = await (await fetch(fcd + '/v1/txs/gas_prices')).json();
    const gasPricesCoins = Object.keys(gasPrices).map(token => new Coin(token, gasPrices[token]));

    return {
      fee: new StdFee(parseInt(gas), feeCoins),
      gasPrices: gasPricesCoins
    }
  }


  // extenstion request
  const submitOrder = async (offer: TokenAsset, ask: TokenAsset, onSuccess = (result: any) => { }, onError = (error: any) => { }) => {
    if (!pairInfo)
      throw new Error(`No data about pair`)

    if (!pairInfo[offer.address])
      throw new Error(`No data about token ${offer.address}`)

    const msgs: any[] = [
      {
        type: "wasm/MsgExecuteContract",
        value: {
          coins: pairInfo[offer.address].isNative
            ? [
              {
                amount: offer.amount,
                denom: offer.address
              }
            ]
            : [],
          contract: LIMIT_ORDER_CONTRACT_ADDRESS,
          execute_msg:
          {
            submit_order: {
              ask_asset: makeTokenInfo(ask.address, ask.amount),
              offer_asset: makeTokenInfo(offer.address, offer.amount)
            },
          },
          sender: walletAddress,
        },
      },
    ]

    if (!pairInfo[offer.address].isNative)
      msgs.unshift({
        type: "wasm/MsgExecuteContract",
        value: {
          coins: [],
          // @ts-ignore
          contract: offer.address,
          execute_msg: {
            increase_allowance: {
              "amount": offer.amount,
              "spender": LIMIT_ORDER_CONTRACT_ADDRESS
            }
          },
          sender: walletAddress,
        },
      })

    const feeOptions = await getFee(offer)

    try {
      const txOptions: CreateTxOptions = {
        msgs: TransformMessageArray(msgs),
        memo: undefined,
        ...feeOptions
      }

      const extensionResult = await terraExtensionPost(txOptions)


      if (extensionResult) {
        return onSuccess(extensionResult)
      }
    }
    catch (error) {
      onError(error)
    }
  }

  // extenstion request
  const cancelOrder = async (orderID, onSuccess = (result: any) => { }, onError = (error: any) => { }) => {
    const msgs: any = [
      {
        type: "wasm/MsgExecuteContract",
        value: {
          coins: [],
          contract: LIMIT_ORDER_CONTRACT_ADDRESS,
          execute_msg: {
            cancel_order: {
              order_id: orderID
            },
          },
          sender: walletAddress,
        },
      }
    ]

    const feeOptions = await getFee()

    try {
      const txOptions: CreateTxOptions = {
        msgs: TransformMessageArray(msgs),
        memo: undefined,
        ...feeOptions
      }

      const extensionResult = await terraExtensionPost(txOptions)


      if (extensionResult) {
        return onSuccess(extensionResult)
      }
    }
    catch (error) {
      onError(error)
    }
  }

  const cancelAllOrders = async (
    orders,
    onSuccess = (result: any) => { },
    onError = (error: any) => { }
  ) => {
    const msgs: any = orders.map((item) => ({
      type: "wasm/MsgExecuteContract",
      value: {
        coins: [],
        contract: LIMIT_ORDER_CONTRACT_ADDRESS,
        execute_msg: {
          cancel_order: {
            order_id: item.order_id
          },
        },
        sender: walletAddress,
      },
    }))

    const feeOptions = await getFee()

    try {
      const txOptions: CreateTxOptions = {
        msgs: TransformMessageArray(msgs),
        memo: undefined,
        ...feeOptions
      }

      const extensionResult = await terraExtensionPost(txOptions)


      if (extensionResult) {
        return onSuccess(extensionResult)
      }
    }
    catch (error) {
      onError(error)
    }
  }

  useEffect(() => {
    setLcdclient(
      new LCDClient({
        URL: fcd,
        chainID: chainID,
        gasPrices: fee,
        gasAdjustment: "1.5",
      })
    )
  }, [])

  return (
    <LimitContext.Provider
      value={{
        lcdclient,
        swapType,
        setSwapType,
        price,
        setPrice,
        getPrice,
        getOrderDetails,
        getOrders,
        cancelOrder,
        submitOrder,
        cancelAllOrders,
        orders,
        setOrders,
        ordersLoading,
        setOrdersLoading,
        fetchOrders
      }}
    >
      {children}
    </LimitContext.Provider>
  );
};

export default LimitContext;

export { LimitContextProvider };
