import React from "react";
import { Link, useLocation } from "react-router-dom";



const NavBarLink = (
    {
        href,
        regex,
        label,
        className
    }: {
        label: string,
        href: string,
        regex?: RegExp,
        className?: string
    }) => {
        const location = useLocation();

    return (
        <Link
            className={`cursor-pointer text-base hover:bg-black-80 rounded-base transition-all duration-300 ${className ?? ""}`}
            key={href}
            to={href+location.search}
        >
            <div
                style={{ whiteSpace: 'nowrap' }}
                className={`px-3 py-2 ${(location.pathname.match(regex) || (location.pathname + location.hash).match(regex)) ? "active color-blue" : "text-white"}`}
            >
                {label}
            </div>
        </Link>
    )
}

export default NavBarLink
