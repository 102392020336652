import { GoogleSpreadsheet } from "google-spreadsheet"

// Config variables
const SPREADSHEET_ID = "190TTCLGzGeiBdl9Ge35A5maaixsbh21hGrpsQTMR7Eo"
const SHEET_ID = "0"

const CLIENT_EMAIL = ""
const PRIVATE_KEY = ""

const doc = new GoogleSpreadsheet(SPREADSHEET_ID)

export default async (email: string) => {
  try {
    let emailCorrect = true

    if (
      !email.match(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      )
    ) {
      emailCorrect = false
    }

    if (emailCorrect) {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY.replace(/\\n/g, "\n"),
      })
      // loads document properties and worksheets
      await doc.loadInfo()

      const sheet = doc.sheetsById[SHEET_ID]
      const rows = await sheet.getRows()

      if (rows.filter((item) => item.email === email).length > 0) return { ok: false, error: "exists" }

      await sheet.addRow({ Email: email })

      return { ok: true }
    } else {
      return { ok: false, error: "field", emailCorrect }
    }
  } catch (e) {
    return { ok: false, error: e.message }
  }
}
