import React from "react";

const ProTradeSwitchRow = ({ className = "", children }) => {
  return (
    <div className={`bg-gray w-full sm:w-max rounded-full flex text-sm md:text-2sm ${className}`}>
      {children}
    </div>
  );
}

export default ProTradeSwitchRow
