import React, { Dispatch, MutableRefObject, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom'

export type TDrawerVariant = 'bottom' | 'top' | 'left' | 'right'

type TDrawerChildren = JSX.Element | ((drawerProps:any) => JSX.Element)
interface IDrawer {
  children: TDrawerChildren,
  open: boolean,
  setOpen?: Dispatch<SetStateAction<boolean>>,
  hideAt?: number,
  hide?: boolean,
  variant?: TDrawerVariant
  className?: string,
  isToken?: boolean
}

const Drawer = ({ children, open = false, setOpen = () => { }, hideAt = 1280, hide = false, variant = 'bottom', className = "", isToken = false }: IDrawer) => {
  const [container, setContainer] = useState(undefined)

  const history = useHistory()
  const listenerRef = useRef() as MutableRefObject<any>

  const drawer = useRef() as MutableRefObject<HTMLDivElement>
  const childrenIsFunction = typeof children === "function"

  useLayoutEffect(() => {
    setContainer(document.getElementById("bottomDrawer"))

    if (!listenerRef.current && hide)
      listenerRef.current = history.listen(() => {
        setOpen(false)
      })
  }, [])

  useLayoutEffect(() => {
    if (drawer.current) {
      if (childrenIsFunction) {
        drawer.current.classList.add("drawer")
        className && drawer.current.classList.add(className)
      }

      drawer.current.setAttribute("data-variant", variant)

      drawer.current.addEventListener("animationend", () => {
        drawer.current.setAttribute("closing", "")
      }, { once: true })

    }
  }, [drawer.current])

  useLayoutEffect(() => {
    drawer.current && drawer.current.setAttribute("data-variant", variant)
  }, [variant])

  const handleResize = (e) => {
    const html = document.querySelector("html")

    if (html.clientWidth >= hideAt) {
      setOpen(false)
    }
  }

  useLayoutEffect(() => {
    const html = document.querySelector("html")
    if (hide && html?.clientWidth >= hideAt) {
      setOpen(false)
    }

    if (open) {
      drawer.current && drawer.current.setAttribute("open", "")

      html.classList.add("overflow-hidden")
      if (hide)
        window.addEventListener("resize", handleResize)
    }
    else {
      drawer.current && drawer.current.removeAttribute("open")

      html.classList.remove("overflow-hidden")
      if (hide)
        window.removeEventListener("resize", handleResize)
    }

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [open, hide])

  const handelClickOutside = (e) => {
    if (e.target.matches("[data-drawer]"))
      setOpen(false)
  }
 
  if (!container )
    return <></>
  

  const _children = childrenIsFunction ? children({ref: drawer, 'data-is-drawer': true}) : children

  return ReactDOM.createPortal(
    <div className={`w-full h-full font-poppins absolute top-0 ${open ? isToken ? 'bg-opacity-80 bg-fade lg:bg-transparent lg:bg-opacity-0' :'bg-opacity-80 bg-fade' : ''}`}
    >
      <div
        onClick={handelClickOutside}
        {...(childrenIsFunction ? {} : { 'data-is-drower': true })}
        className={`bg-opacity-100 absolute w-full h-full  ${open ? "child-pointer-events-all" : ""}  ${className || ""}`}
      >
        {React.cloneElement(_children, { "data-drawer": true}, _children.props.children)}
      </div>
    </div>,
    container
  );
}

export default Drawer
