import React from "react"
import Skeleton from "react-loading-skeleton";

const SkeletonLoading = ({ loading, count = 1, children, className = "" }) => {
  if (loading)
    return (
      <div className={`${className}`}>
        <Skeleton {...{ count }} baseColor="#45434F" highlightColor="#6e6b7a59" enableAnimation={true} />
      </div>
    )
  return (
    children
  );
}

export default SkeletonLoading