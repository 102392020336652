import { LCDClient } from '@terra-money/terra.js'
import axios from 'axios'
// import { Type } from 'pages/Swap'
// import Type
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import PageLoaderContext from './pageLoader'
import { Type } from './swap'

const PairContext = React.createContext({
	tokensInfo: undefined,
	timeFrameIndex: 1,
	timeFrameIndexLuquidity: 3,
	timeFrameIndexVolume: 3,
	setTokensInfo: (arg: any) => {
	},
	setTimeFrame: (index: number) => {
	},
	setTimeFrameLiquidity: (index: number) => {
	},
	setTimeFrameVolume: (index: number) => {
	},
	getInfo: (id: string) => {
		return {}
	},
	getTokenInfo: (address: string) => {
		return {}
	},
	navigateTo: null,
	setNavigateTo: (to: Type | null) => {
	},
	pairAddress: "",
	setPairAddress: (address: string) => {
	},
})

interface Props {
	children: ReactNode;
}

export const PairChartTimePeriods = [
	{
		value: 1,
		label: "D"
	},
	{
		value: 7,
		label: "W"
	},
	{
		value: 30,
		label: "M"
	},
]

export const PairChartTimePeriodsFull = [
	{
		value: 1 / 24,
		label: "1H"
	},
	{
		value: 4 / 24,
		label: "4H"
	},
	{
		value: 0.5,
		label: "12H"
	},
	{
		value: 1,
		label: "D"
	},
	{
		value: 7,
		label: "W"
	},
	{
		value: 30,
		label: "M"
	},
]

const PairContextProvider = ({ children }: Props) => {

	const [tokensInfo, setTokensInfo] = useState<any>()
	const [timeFrameIndex, setTimeFrameIndex] = useState(1)
	const [timeFrameIndexLuquidity, setTimeFrameIndexLuquidity] = useState(5)
	const [timeFrameIndexVolume, setTimeFrameIndexVolume] = useState(5)

	const [navigateTo, setNavigateTo] = useState<Type | null>(null)
	const [pairAddress, setPairAddress] = useState("")

	const [lcdclient, setLcdclient] = useState<LCDClient>()

	const { setMessage } = useContext(PageLoaderContext)

	const setTimeFrame = (index: number) => {
		setTimeFrameIndex(index % PairChartTimePeriods.length)
	}

	const setTimeFrameLiquidity = (index: number) => {
		setTimeFrameIndexLuquidity(index % PairChartTimePeriodsFull.length)
	}

	const setTimeFrameVolume = (index: number) => {
		setTimeFrameIndexVolume(index % PairChartTimePeriodsFull.length)
	}

	useEffect(() => {

		setLcdclient(
			new LCDClient({
				URL: process.env.REACT_APP_WEB3_TERRA_CLASSIC_FCD,
				chainID: "columbus-5",
				gasPrices: { gasPrice: '0.00506', amount: '1518', gas: '2000000' },
				gasAdjustment: "1.5",
			})
		)

		setMessage("Initializing wallet")
	}, [])

	const getTokenInfo = async (address) => {
		if (address === "uusd") {
			return {
				divider: 1000000,
				decimals: 6,
				symbol: "UST",
			}
		}

		const tokenData = await (await axios.get(`${process.env.REACT_APP_WEB3_TERRA_CLASSIC_FCD}/wasm/contracts/${address}/store?query_msg={"token_info":{}}`)).data

		return {
			divider: Math.pow(10, tokenData.result.decimals),
			decimals: tokenData.result.decimals,
			symbol: tokenData.result.symbol,
		}
	}

	const getInfo = async (id) => {
		if (!lcdclient) return null

		const result: any = await lcdclient.wasm.contractQuery(
			// @ts-ignore
			id,
			{ pool: {} } // query msg
		)

		const tokensInfoTemp = {}

		for (let asset of result?.assets) {
			if (asset.info.native_token) {
				tokensInfoTemp[asset.info.native_token.denom] = {
					divider: 1000000,
					decimals: 6,
					symbol: "UST",
					amount: asset.amount
				}
				continue
			}
			const tokenData = await (await axios.get(`${process.env.REACT_APP_WEB3_TERRA_CLASSIC_FCD}/wasm/contracts/${asset.info.token.contract_addr}/store?query_msg={"token_info":{}}`)).data

			tokensInfoTemp[asset.info.token.contract_addr] = {
				divider: Math.pow(10, tokenData.result.decimals),
				decimals: tokenData.result.decimals,
				symbol: tokenData.result.symbol,
				amount: asset.amount
			}
		}

		const keys = Object.keys(tokensInfoTemp)
		tokensInfoTemp["token0"] = keys[0]
		tokensInfoTemp["token1"] = keys[1]

		return tokensInfoTemp
	}


	return (
		<PairContext.Provider
			value={{
				tokensInfo,
				setTokensInfo,
				getInfo,
				setTimeFrame,
				timeFrameIndex,
				setTimeFrameLiquidity,
				timeFrameIndexLuquidity,
				setTimeFrameVolume,
				timeFrameIndexVolume,
				navigateTo,
				setNavigateTo,
				pairAddress,
				setPairAddress,
				getTokenInfo
			}}
		>
			{children}
		</PairContext.Provider>
	)
}

export default PairContext

export { PairContextProvider }
