import TokenContext from 'layouts/TokenContext';
import React, { MutableRefObject, ReactNode, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import tokensDictionary from "constants/tokensDictionary.json"
import { Type } from './swap';
import axios from 'rest/request';

const ProSwapContext = React.createContext({
    activePair: {
        id: "",
        token1: "",
        token2: "",
        primary: "",
        secondary: "",
        primarySymbol: "",
        secondarySymbol: "",
        symbol1: "",
        symbol2: "",
        market: ""
    },
    pairInfo: null,
    pairData: null,
    isLoading: false,
    tokens: { token1: "", token2: "", symbol1: "", symbol2: "" },
    setTokens: (arg: any) => { },
    setIsLoading: (arg: any) => { },
    setActivePair: (arg: any) => { },
    setPairInfo: (arg: any) => { },
    setPairData: (arg: any) => { },
    tokenIcons: { iconFirstToken: "", iconSecondToken: "" },
    setTokenIcons: (arg: any) => { },
    tokenPrice: { firstPrice: "0", secondPrice: "0" },
    setTokenPrice: (arg: any) => { },
    markets: { current: [] } as MutableRefObject<any[]>
})

interface Props {
    children: ReactNode;
}

interface ActivePair {
    id: string | null;
    token1: string;
    token2: string;
    primary: string;
    secondary: string;
    primarySymbol: string;
    secondarySymbol: string;
    symbol1: string;
    symbol2: string;
    market: string
}

export enum ETradeUrlSearchParams {
    TOKEN0 = "token0",
    TOKEN1 = "token1",
    MARKET = "market",
}

export enum EDefaultToken {
    TOKEN0 = "uluna",
    TOKEN1 = "uusd"
}

export const getTokensStatus: (address0: string, address1: string) => {
    primary: string;
    secondary: string;
} = (address0, address1) => {
    if (address0 === "uusd")
        return ({
            primary: address1,
            secondary: address0
        })

    if (address1 === "uusd")
        return ({
            primary: address0,
            secondary: address1
        })

    if (address0 === "uluna")
        return ({
            primary: address1,
            secondary: address0
        })

    if (address1 === "uluna")
        return ({
            primary: address0,
            secondary: address1
        })

    return {
        primary: address0,
        secondary: address1
    }
}

const getSymbol = (address, assetsInfo) => (assetsInfo[address].contractAddr.toLowerCase() === address.toLowerCase() ? tokensDictionary[address] : assetsInfo[address])?.symbol ?? ""

const ProSwapContextProvider = ({ children }: Props) => {
    const { assetsInfo, pairsInfo, assetsInfoLoading, getPair } = useContext(TokenContext);
    const location = useLocation()
    const history = useHistory()

    const [activePair, setActivePair] = useState<ActivePair>({
        id: "",
        token1: "",
        token2: "",
        symbol1: "",
        symbol2: "",
        primary: "",
        secondary: "",
        primarySymbol: "",
        secondarySymbol: "",
        market: ""

    })

    const [pairInfo, setPairInfo] = useState(null)
    const [pairData, setPairData] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [tokens, setTokens] = useState<any>({})
    const [tokenIcons, setTokenIcons] = useState(null);
    const [tokenPrice, setTokenPrice] = useState(null);

    const [addresses, setAddresses] = useState([])

    const markets = useRef() as MutableRefObject<any[]>

    useLayoutEffect(() => {
        if (location.hash === `#${Type.SWAP}` || location.hash === "") {
            if (Object.keys(pairsInfo).length && Object.keys(assetsInfo).length) {
                const params = new URLSearchParams(location.search);
                const token0 = params.get(ETradeUrlSearchParams.TOKEN0)
                const token1 = params.get(ETradeUrlSearchParams.TOKEN1)
                const marketFromURL = params.get(ETradeUrlSearchParams.MARKET)

                const address0 = assetsInfo[token0]?.contractAddr || EDefaultToken.TOKEN0
                const address1 = assetsInfo[token1]?.contractAddr || EDefaultToken.TOKEN1

                const { primary, secondary } = getTokensStatus(address0, address1)
                const primarySymbol = getSymbol(primary, assetsInfo)
                const secondarySymbol = getSymbol(secondary, assetsInfo)

                const newAddress = [address0, address1].sort()

                const symbol0 = getSymbol(address0, assetsInfo)
                const symbol1 = getSymbol(address1, assetsInfo)

                const pair = getPair(token0, token1)
                const selectedPair = (pair ?? []).find(item => item.market === marketFromURL) ?? pair[0]

                if ((activePair.id || undefined) === selectedPair?.contractAddr && addresses.length !== 0 && addresses.every((item, index) => item === newAddress[index]))
                    return

                setAddresses(newAddress)
                setActivePair({
                    id: selectedPair?.contractAddr ?? null,
                    token1: address0,
                    token2: address1,
                    symbol1: symbol0,
                    symbol2: symbol1,
                    primary,
                    secondary,
                    primarySymbol,
                    secondarySymbol,
                    market: selectedPair?.market ?? null
                })
            }
        }
    }, [location, addresses]);

    return (
        <ProSwapContext.Provider
            value={{
                activePair,
                pairInfo,
                pairData,
                tokens,
                setIsLoading,
                isLoading,
                setTokens,
                setActivePair,
                setPairData,
                setPairInfo,
                tokenIcons,
                setTokenIcons,
                tokenPrice,
                setTokenPrice,
                markets,
            }}
        >
            {children}
        </ProSwapContext.Provider>
    )
}

export default ProSwapContext

export { ProSwapContextProvider as ProSwapContextProvider }
