import React, {useContext} from 'react'
import {useAddress} from '../../hooks'
import FavoriteContext from './context/favorite'
import FavoritesChosen from './analytics/icons/favoritesChosen'
import Favorites from './icons/favorites'
import {TooltipIconFavorites} from '../TooltipFavorites'

interface Props {
	address: string;
	forTokens: boolean;
	width?: string;
	height?: string;
}

const FavoritesComponent = ({address, forTokens = false, width = '32', height = '32'}: Props) => {
	const wallet = useAddress()
	const {pairsHandler, pairs, tokens, tokensHandler} = useContext(FavoriteContext)

	return (
		<div
			onClick={(e) => {
				(forTokens ? tokensHandler : pairsHandler)(e, address)
			}}
			className="h-8 flex items-center cursor-pointer hover:opacity-70 transition-all duration-300 mr-3"
		>
			{!wallet
				?
				<TooltipIconFavorites width={width} height={height} content="Connect your wallet to add to favorites"/>
				:
				<>
					{(forTokens ? tokens : pairs).includes(address)
						?
						<div className="w-8 h-8 flex items-center justify-center">
							<FavoritesChosen width="16" height="16"/>
						</div>
						:
						<Favorites width={width} height={height}/>
					}
				</>
			}
		</div>
	)
}

export default FavoritesComponent
