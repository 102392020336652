import FavoriteContext from "components/platform/context/favorite";
import { getTokensStatus } from "components/platform/context/proSwap";
import SwapContext from "components/platform/context/swap";
import USTPriceContext from "components/platform/context/USTprice";
import SkeletonLoading from "components/platform/layout/SkeletonLoading";
import RunningLine from "components/platform/runningLine";
import formatNumbers from "helpers/formatNumbers";
import TokenContext from "layouts/TokenContext";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Favorites from "./icons/favorites";
import TrashBin from "./icons/trashBin";
import PairImages from "./PairImages";
import PriceGraph from "./priceGraph";
import dexList from "constants/dexList.json";



const FavPoolCard = ({
    token0Address,
    token1Address,
    contractAddr,
    priceInverted,
    diff24,
    children,
    removeToken,
    index,
    market
}) => {
    const { assetsInfo } = useContext(TokenContext);
    const { setTokensOpen } = useContext(SwapContext)
    const { ustPrice } = useContext(USTPriceContext)
    const { pairs, pairsHandler } = useContext(FavoriteContext);

    return (
        <div className={`group relative  items-center rounded-lg ${index === 0 ? "" : "mt-1"
            } border-px-black60 hover:bg-black-80 transition-all`} >
            <div className="relative mb-2 px-2 pt-2">
                <div>
                    <div className="grid-cols-max-fr mb-1">
                        <div className="flex items-center">
                            <div className="flex items-center">
                                <PairImages primary={getTokensStatus(token1Address, token0Address).primary} secondary={getTokensStatus(token1Address, token0Address).secondary} />
                            </div>
                            {assetsInfo && (
                                <Link to={`/analytics/pairs/${contractAddr}`} onClick={() => setTokensOpen(false)} className='group-hover:text-theme-blue mr-1'>
                                    <div className="flex">
                                        <span className="text-sm">
                                            {assetsInfo[getTokensStatus(token1Address, token0Address).primary]?.symbol}
                                            <span className="text-header-tab group-hover:text-theme-blue">
                                                {assetsInfo[getTokensStatus(token1Address, token0Address).secondary]?.symbol}
                                            </span>
                                        </span>
                                    </div>
                                </Link>
                            )}
                            <img className="w-4 h-4 my-auto bg-black-80 rounded-full" src={dexList[market.toLowerCase()]?.image} />
                        </div>
                    </div>
                </div>
                <div className="w-full flex">
                    <SkeletonLoading loading={!ustPrice}>
                        <div className="flex mr-2">
                            <>
                                <span className="opacity-70">$</span>{" "}
                                {formatNumbers(priceInverted * ustPrice)}
                            </>
                        </div>
                    </SkeletonLoading>
                    <div className="w-max" >

                        <SkeletonLoading loading={!ustPrice}>
                            <span
                                className={`text-sm ${Number(diff24) >= 0
                                    ? "text-action"
                                    : "text-red"
                                    }`}
                            >
                                {Number(diff24) > 0 ? "+" : ""}
                                {Number(diff24).toFixed(2)}%
                            </span>
                        </SkeletonLoading>
                    </div>
                </div>
            </div>
            {children}
            <div
                className={` absolute top-0 right-0 flex rounded-lg transition-all duration-300 h-full px-3 h-min-8 opacity-0 group-hover:opacity-100 cursor-pointer items-center justify-center bg-black-80 blur-40`}
                onClick={(e) => {
                    removeToken(contractAddr);
                }}
            >
                <div className="h-8 w-8 ">
                    {pairs.includes(contractAddr) ? (
                        <div className="w-8 h-8 flex items-center justify-center bg-black-90 rounded-base"
                        >
                            <TrashBin />
                        </div>
                    ) : (
                        <div className="w-8 h-8 flex items-center justify-center"
                        >
                            <Favorites width="32" height="32" />
                        </div>
                    )}
                </div>
            </div >
        </div >
    )
}

export default FavPoolCard
