import React from "react"

const CheckMark = () => {
    return (
        <svg width="44" height="44" className={`wFull hFull`} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="22" cy="22" r="22" fill="#319E4E" />
            <path d="M33 15L19 29L12 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default CheckMark