import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  useContext,
} from "react";
import { useAddress, useConnectModal } from "hooks";
import Button from "components/platform/button";
import LoaderIcon from "components/platform/icons/loader";
import tokensDictionary from "constants/tokensDictionary.json";
import axios from "axios";
import TokenContext from "layouts/TokenContext";
import { Link, useLocation } from "react-router-dom"
import { Line, LineChart, ResponsiveContainer } from "recharts";
import ChevronRight from "./icons/ChevronRight";
import FavoritesChosen from "./icons/favoritesChosen";
import Favorites from "./icons/favorites";
import { useInView } from "react-intersection-observer";
import { getTokenIcon } from "helpers/token";
import FavoriteContext from "components/platform/context/favorite";
import SwapContext from '../platform/context/swap'
import formatNumbers from '../../helpers/formatNumbers'
import useAPI from "rest/useAPI";
import USTPriceContext from "components/platform/context/USTprice";
import SkeletonLoading from "components/platform/layout/SkeletonLoading";
import TrashBin from "./icons/trashBin";
import FavMyTokensItem from "./FavMyTokensItem";
import PriceGraph from "./priceGraph";
import Accordion from "components/platform/accordion";

const limit = 10;
const gqlSeparator = ","

const NewMyTokens = ({ isGraph }) => {
  const { setTokensOpen } = useContext(SwapContext)
  const { assetsInfo, pairsInfo } = useContext(TokenContext);
  const { tokens: favoriteTokens, tokensHandler } = useContext(FavoriteContext);
  const { ustPrice } = useContext(USTPriceContext)
  const { loadDenomBalance } = useAPI();
  const [mountedIndex, setMountedIndex] = useState(0)


  const [loading, setLoading] = useState(true);
  const [allTokens, setAllTokens] = useState<any[]>(null);

  const [page, setPage] = useState(0);
  const [displayedTokens, setDisplayedTokens] = useState([]);

  const address = useAddress()

  const location = useLocation();

  const connectModal = useConnectModal();

  const [endPageRef, endPageInView] = useInView({
    threshold: 0,
  });

  const openConnect = useCallback(() => connectModal.open(), []);

  const getNativeBalance = async () => {
    const denomInfos = await loadDenomBalance();
    let temp = [];

    if (denomInfos !== undefined) {
      denomInfos.forEach((denomInfo) => {
        temp.push({
          address: denomInfo.denom.toLocaleLowerCase(),
          amount: +denomInfo.amount / (10 ** tokensDictionary[denomInfo.denom.toLocaleLowerCase()].decimals),
        });
      });
    }

    return temp;
  };


  async function getTokens() {
    let queries = "";

    const native = await getNativeBalance();

    Object.keys(tokensDictionary).forEach((item) => {
      if (item.match("(terra1)")) queries += getBalance(item, address) + " ";
    });

    const response = await axios.post("https://mantle.terra.dev/", {
      query: "{" + queries + "}",
    });

    if (response.status !== 200 && !response.data) return setLoading(false);

    const tokens = [];

    Object.keys(response.data.data)
      .filter((item) => !response.data.data[item]?.Result.match(/:"0"/))
      .forEach((item) => {
        if (response?.data?.data[item])
          tokens.push({
            address: item.toLocaleLowerCase(),
            amount: JSON.parse(response.data.data[item].Result).balance / (10 ** tokensDictionary[item.toLocaleLowerCase()].decimals),
          });
      });

    const result = native.concat(tokens);

    let verifyWithUst = [];

    let nonVerifyWithUsd = [];

    result.forEach((el) => {
      const hasPairWithUST = tokensDictionary.uusd.pairWith.includes(el.address)

      if (hasPairWithUST) {
        verifyWithUst.push({
          ...el,
          verify: true,
          ustPairAddress: pairsInfo[`${el.address + "uusd"}`].contractAddr,
        });
      } else {
        nonVerifyWithUsd.push({ ...el, verify: false, contractAddr: "none" });
      }
    });

    setAllTokens(() => [...verifyWithUst, ...nonVerifyWithUsd]);
  }

  async function setForDisplay() {
    const queryStrings: {
      tokensAddresses: string
      pairsAddresses: string
    } = (
      allTokens.length < limit
        ? allTokens
        : allTokens.slice(page * limit, page * limit + limit)
    ).reduce((prev, current, index) => ({
      tokensAddresses: prev.tokensAddresses + (index !== 0 ? gqlSeparator : "") + current.address,
      pairsAddresses: prev.pairsAddresses + (index !== 0 ? gqlSeparator : "") + current.ustPairAddress
    }),
      { tokensAddresses: "", pairsAddresses: "" }
    )

    const response = await axios.post(process.env.REACT_APP_ANALYTICS_GQL_API, {
      query: `
        query MyTokens {
          balance(contractAddrs: "${queryStrings.tokensAddresses}") {
            price
            priceInverted
          }
          period(contractAddrList: "${queryStrings.pairsAddresses}",token0: "uusd", limit: 10) {                    
            content {
              pairPriceWeek
            }
          }
        }
        `,
    })

    if (!response.status || !response.data?.data) {
      setDisplayedTokens([...displayedTokens, ...allTokens.slice(page * limit, page * limit + limit)]);
      setPage((prev) => prev + 1);
      setLoading(false);


      return
    }
    const balances = response.data.data.balance
    const priceHistory = response.data.data.period.content

    const newTokensToDisplay = allTokens
      .slice(page * limit, page * limit + limit)
      .map((item, index) => {
        return {
          ...item,
          price: [index] ? balances[index]?.priceInverted : null,
          pairPriceWeek: priceHistory[index] ? priceHistory[index].pairPriceWeek : null,
        };
      });

    setDisplayedTokens([...displayedTokens, ...newTokensToDisplay]);
    setPage((prev) => prev + 1);
    setLoading(false);
  }

  useEffect(() => {
    if (allTokens) {
      setForDisplay();
    }
  }, [allTokens]);

  const getBalance = useCallback((tokenAddress, address) => {
    return `${tokenAddress}: WasmContractsContractAddressStore(
      ContractAddress:"${tokenAddress}",
      QueryMsg: "{\\"balance\\":{\\"address\\":\\"${address}\\"}}")
    {
      Result
    }`;
  }, []);

  useLayoutEffect(() => {
    if (Object.keys(pairsInfo).length !== 0) {
      if (!address) return setLoading(false);

      getTokens();
    }
  }, [address, pairsInfo]);

  useEffect(() => {
    if (allTokens) {
      if (allTokens.length > displayedTokens.length && endPageInView) {
        setForDisplay();
      }
    }
  }, [endPageInView, allTokens]);

  return (
    <div className="w-full h-full">
      {!address ? (
        <div className="px-4 my-4">
          <Button className="w-full" onClick={openConnect}>
            Connect Wallet
          </Button>
        </div>
      ) : (
        <div className="h-full">
          {loading ? (
            <div className="h-full">
              <div
                className="h-full w-full flex justify-center items-center p-4"
              >
                <div className="w-10 h-10">
                  <LoaderIcon />
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full px-2 ">
              <div
                className={`flex w-full pt-4 xl:pt-0 pr-3`}
              >
                <div
                  className={`transition-opacity ${!isGraph
                    ? "flex flex-basis-60"
                    : "hidden"
                    }`}
                >
                </div>
              </div>
              <div
                className={`mt-2 pb-4 scrollbar-tune overflow-y-auto xxxl:max-h-572 ${displayedTokens.length > 9 ? "pr-3" : ""
                  } ${displayedTokens.length > 6 && isGraph ? "px-3" : ""}`}
              >
                {displayedTokens.length > 0 ? (
                  <div>
                    {displayedTokens.map((token, index) => (
                      <Link to={() => {
                        const params = new URLSearchParams(location.search);

                        params.set("token0", token.address);

                        return location.pathname + "?" + params.toString();
                      }}
                      >
                        <FavMyTokensItem
                          addToken={tokensHandler}
                          removeToken={tokensHandler}
                          {...token}
                        >
                          {token.verify && token.pairPriceWeek && (
                            <Accordion open={isGraph}>
                              <div className="h-10">
                                  <PriceGraph data={token.pairPriceWeek} onMount={() => setMountedIndex(prev => prev + 1)} />
                              </div>
                            </Accordion>
                          )}
                        </FavMyTokensItem>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <>
                    <div
                      className="col-span-1 px-2 flex justify-center items-center"
                      style={{ height: "60px" }}
                    >
                      <p className="ml-2 opacity-70">
                        Your favorite list is empty
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NewMyTokens;
