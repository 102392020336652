import React, { StrictMode } from "react"
const TradeIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 14C9.5878 14 10.6512 13.6774 11.5556 13.0731C12.4601 12.4687 13.1651 11.6098 13.5813 10.6048C13.9976 9.59977 14.1065 8.4939 13.8943 7.427C13.6821 6.36011 13.1583 5.3801 12.3891 4.61091C11.6199 3.84173 10.6399 3.3179 9.57299 3.10568C8.5061 2.89346 7.40023 3.00238 6.39524 3.41866C5.39025 3.83495 4.53126 4.5399 3.92692 5.44437C3.32257 6.34884 3 7.4122 3 8.5C3.00261 9.95789 3.5829 11.3553 4.61379 12.3862C5.64468 13.4171 7.04211 13.9974 8.5 14ZM8.5 3.98567C9.39006 3.98762 10.2596 4.25321 10.9988 4.74891C11.7381 5.24462 12.3139 5.94822 12.6536 6.7709C12.9933 7.59359 13.0817 8.49847 12.9076 9.37133C12.7335 10.2442 12.3046 11.0459 11.6753 11.6753C11.0459 12.3047 10.2442 12.7335 9.37133 12.9076C8.49847 13.0817 7.59358 12.9933 6.7709 12.6536C5.94822 12.3139 5.24462 11.7381 4.74891 10.9988C4.2532 10.2596 3.98761 9.39006 3.98566 8.5C3.98436 7.90681 4.10024 7.31919 4.32665 6.7709C4.55306 6.22261 4.88553 5.72444 5.30498 5.30499C5.72444 4.88553 6.22261 4.55306 6.7709 4.32665C7.31919 4.10025 7.9068 3.98437 8.5 3.98567Z" fill="currentColor" />
      <path d="M19.5 14C18.4122 14 17.3488 14.3226 16.4444 14.9269C15.5399 15.5313 14.8349 16.3902 14.4187 17.3952C14.0024 18.4002 13.8935 19.5061 14.1057 20.573C14.3179 21.6399 14.8417 22.6199 15.6109 23.3891C16.3801 24.1583 17.3601 24.6821 18.427 24.8943C19.4939 25.1065 20.5998 24.9976 21.6048 24.5813C22.6098 24.1651 23.4687 23.4601 24.0731 22.5556C24.6774 21.6512 25 20.5878 25 19.5C25 18.7777 24.8577 18.0625 24.5813 17.3952C24.3049 16.728 23.8998 16.1216 23.3891 15.6109C22.8784 15.1002 22.2721 14.6951 21.6048 14.4187C20.9375 14.1423 20.2223 14 19.5 14ZM19.5 24.0045C18.6091 24.0045 17.7382 23.7403 16.9974 23.2453C16.2567 22.7504 15.6793 22.0469 15.3384 21.2238C14.9975 20.4007 14.9083 19.495 15.0821 18.6212C15.2559 17.7474 15.6849 16.9448 16.3149 16.3149C16.9448 15.6849 17.7474 15.2559 18.6212 15.0821C19.495 14.9083 20.4007 14.9975 21.2238 15.3384C22.0469 15.6793 22.7504 16.2567 23.2453 16.9974C23.7403 17.7382 24.0045 18.6091 24.0045 19.5C24.0045 20.0915 23.888 20.6773 23.6616 21.2238C23.4352 21.7703 23.1034 22.2669 22.6851 22.6851C22.2669 23.1034 21.7703 23.4352 21.2238 23.6616C20.6773 23.888 20.0915 24.0045 19.5 24.0045Z" fill="currentColor" />
      <path d="M16.5004 8.99279H22.5746L21.5171 10.2108C21.4463 10.3012 21.4086 10.4189 21.4117 10.5398C21.4149 10.6606 21.4586 10.7756 21.534 10.8611C21.6094 10.9466 21.7107 10.9962 21.8173 10.9998C21.9239 11.0034 22.0276 10.9606 22.1073 10.8803L23.8779 8.85332C23.915 8.81036 23.9443 8.75948 23.964 8.70362C23.9837 8.64777 23.9934 8.58804 23.9927 8.52789C24.0024 8.46953 24.0024 8.4096 23.9927 8.35123C23.9658 8.2865 23.9266 8.22934 23.8779 8.18387L22.0991 6.11974C22.0194 6.03941 21.9157 5.99665 21.8091 6.00021C21.7025 6.00376 21.6012 6.05337 21.5258 6.13889C21.4504 6.2244 21.4067 6.33936 21.4035 6.46024C21.4004 6.58112 21.4381 6.6988 21.5089 6.78919L22.591 8.01651H16.5004C16.4398 8.00268 16.3771 8.0043 16.3171 8.02125C16.257 8.0382 16.201 8.07007 16.1532 8.11455C16.1053 8.15903 16.0667 8.215 16.0402 8.27845C16.0137 8.34189 16 8.41121 16 8.4814C16 8.5516 16.0137 8.62092 16.0402 8.68436C16.0667 8.7478 16.1053 8.80378 16.1532 8.84826C16.201 8.89274 16.257 8.9246 16.3171 8.94156C16.3771 8.95851 16.4398 8.96013 16.5004 8.9463V8.99279Z" fill="currentColor" />
      <path d="M11.6569 18.9881H5.44901L6.51299 17.7793C6.58304 17.6942 6.62203 17.5838 6.62255 17.4693C6.62306 17.3548 6.58507 17.2441 6.51579 17.1582C6.44651 17.0723 6.35077 17.0172 6.24676 17.0034C6.14276 16.9896 6.03774 17.0181 5.95168 17.0833L4.12533 19.1255C4.08614 19.1672 4.05483 19.217 4.03317 19.272C4.01128 19.3285 4 19.3892 4 19.4506C4 19.5119 4.01128 19.5726 4.03317 19.6291C4.05234 19.6833 4.08077 19.7331 4.11695 19.7756L5.9433 21.772C5.97698 21.8338 6.02328 21.8863 6.07857 21.9253C6.13387 21.9643 6.19667 21.9889 6.26207 21.997C6.32747 22.0051 6.39371 21.9966 6.45562 21.9722C6.51752 21.9477 6.57342 21.908 6.61895 21.856C6.66448 21.804 6.69841 21.7413 6.7181 21.6726C6.73778 21.604 6.74268 21.5313 6.73243 21.4602C6.72217 21.3891 6.69703 21.3216 6.65896 21.2629C6.6209 21.2042 6.57094 21.1559 6.51299 21.1218L5.44901 19.913H11.6569C11.7537 19.8917 11.8407 19.8345 11.9033 19.751C11.9658 19.6676 12 19.563 12 19.4551C12 19.3472 11.9658 19.2427 11.9033 19.1592C11.8407 19.0757 11.7537 19.0185 11.6569 18.9973V18.9881Z" fill="currentColor" />
    </svg>
  )
}

export default TradeIcon