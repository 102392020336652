import React, { useEffect, useContext, useState, useRef, MutableRefObject, useLayoutEffect } from "react";
import FavoriteContext from "components/platform/context/favorite";
import axios from "axios";
import TokenContext from "layouts/TokenContext";
import { Link } from "react-router-dom";
import { Line, LineChart, ResponsiveContainer } from "recharts";
import ChevronRight from "./icons/ChevronRight";
import FavoritesChosen from "./icons/favoritesChosen";
import Favorites from "./icons/favorites";
import formatNumbers from '../../helpers/formatNumbers'
import { getTokenIcon } from '../../helpers/token'
import SwapContext from '../platform/context/swap'
import PriceGraph from "./priceGraph";
import SkeletonLoading from "components/platform/layout/SkeletonLoading";
import USTPriceContext from "components/platform/context/USTprice";
import TrashBin from "./icons/trashBin";
import FavPoolCard from "./FavPoolCard";
import usePairSelect from "hooks/usePairSelect";
import Accordion from "components/platform/accordion";

const idPrefix = "favorite-pair:"

const FavoritePoolsList = ({ active, isGraph }) => {
  const { setTokensOpen } = useContext(SwapContext)
  const { pairs, pairsHandler, removePair } = useContext(FavoriteContext);
  const { assetsInfo } = useContext(TokenContext);
  const [pairsData, setPairsData] = useState([]);
  const [removmentPairId, setRemovementPairId] = useState(null);

  const [mountedIndex, setMountedIndex] = useState(0)
  const [displayGraph, setDisplayGraph] = useState<Record<string, boolean>>({})

  const containterRef = useRef() as MutableRefObject<HTMLDivElement>
  const intersectionObserver = useRef() as MutableRefObject<IntersectionObserver>
  const { ustPrice } = useContext(USTPriceContext)
  const [prevLen, setPrevLev] = useState(0)
  const handlePairSelect = usePairSelect()



  const onObserve = (entries: IntersectionObserverEntry[]) => {
    let temp = {}

    entries.forEach((entrie) => {
      if (entrie.isIntersecting) {
        entrie.target.setAttribute("loaded", "true")
        temp[entrie.target.getAttribute("data-address")] = true
        intersectionObserver.current.unobserve(entrie.target)
      }
    })

    setDisplayGraph((prev) => ({ ...prev, ...temp }))
  }

  useLayoutEffect(() => {
    if (!active) {
      setDisplayGraph({})
      setMountedIndex(0)
      intersectionObserver.current?.disconnect()
    }

    if (!pairsData.length || !containterRef.current)
      return

    const elemetns = containterRef.current.querySelectorAll(`[id*="${idPrefix}"]`)

    if (!intersectionObserver.current)
      intersectionObserver.current = new IntersectionObserver(onObserve, { root: containterRef.current })

    elemetns.forEach((element) => {
      intersectionObserver.current.observe(element)
    })

  }, [pairsData, active])

  useLayoutEffect(() => {
    if (!isGraph)
      setMountedIndex(0)
  }, [isGraph])

  useLayoutEffect(() => {
    if (active && containterRef.current) {
      containterRef.current.scrollTo({ top: 0, behavior: "auto" })
    }
  }, [active])

  async function getData() {
    const pairsString = pairs.join(",")

    const pairsRes = (
      await axios.post(process.env.REACT_APP_ANALYTICS_GQL_API, {
        query: `
                  query FavoritePoolsList {
                    period(contractAddrList: "${pairsString}",token0: "uusd") {
                      content {
                        contractAddr
                        token0Address
                        token1Address
                        priceInverted
                        diff1
                        diff24
                        diff168
                        absoluteVolume24
                        verify
                        liquidity
                        pairPriceWeek
                        price
                        market
                      }
                      pageInfo {
                        asc
                        count
                        limit
                        skip
                        sortField
                      }
                    }
                  }
                  `,
      })
    ).data.data.period.content;
    setPairsData([...pairsRes]);
  }

  useEffect(() => {
    if (prevLen < pairs.length && pairs.length > 0) {
      getData();
      setPrevLev(pairs.length)
    }
  }, [pairs]);

  return (
    <div
      ref={containterRef}
      className={`mt-2 pb-2 scrollbar-tune overflow-y-auto xxxl:max-h-572 px-3 h-full`}
    >
      {pairsData.length > 0 ? (
        <>
          {pairsData.map((item, index) => (
            <div
              onClick={() => handlePairSelect(item.token0Address, item.token1Address)}
              className='cursor-pointer'
            >
              <FavPoolCard
                isGraph={isGraph}
                {...item}
                removeToken={removePair}
                index={index}
                id={idPrefix + item.contractAddr}
                dataAddress={item.contractAddr}
              >
                {item.pairPriceWeek &&
                  <Accordion open={isGraph}>
                    <div className="h-10">
                      <PriceGraph data={item.pairPriceWeek} index={index} />
                    </div>
                  </Accordion>
                }
              </FavPoolCard>
            </div>

          ))}
        </>


      ) : (
        <>
          <div
            className="col-span-1 px-2 flex justify-center items-center"
            style={{ height: "60px" }}
          >
            <p className="ml-2 opacity-70">Your favorite list is empty</p>
          </div>
        </>
      )}
    </div>
  );
};

export default FavoritePoolsList;
