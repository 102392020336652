import React, { useEffect, useState } from 'react'
import { Area, AreaChart, Line, LineChart, ResponsiveContainer } from 'recharts'
const graphMountDelay = 100

const PriceGraph = (
  {
    data,
    onMount = () => { },
    index = 0,
    stats = false,
    margin = { top: 0, right: 0, left: 0, bottom: 0 }
  }
) => {
  const [isRender, setIsRender] = useState(false)
  const [color, setColor] = useState("#57bd0f")

  useEffect(() => {
    if (!data)
      return
    setColor(
      data[0]?.priceInverted <
        data[data.length - 1]
          ?.priceInverted
        ? "#5aff86"
        : "#fc5b5b"
    )
  }, [data])

  useEffect(() => {
    setTimeout(() => {
      setIsRender(true)
    }, index * graphMountDelay)
  }, [])

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
    >
      <AreaChart data={data}
        margin={margin}
      >
        <defs>
          {
            stats ?
              <linearGradient id={color + index + data[0]?.priceInverted + data[0]?.timestamp} x1="0" y1="0" x2="0" y2="1">
                <stop offset="20%" stopColor={color} stopOpacity={0.1} />
                <stop offset="80%" stopColor={color} stopOpacity={0} />
              </linearGradient>
              :
              <linearGradient id={color + index + data[0]?.priceInverted + data[0]?.timestamp} x1="0" y1="0" x2="0" y2="1">
                <stop offset="3%" stopColor={color} stopOpacity={0.5} />
                <stop offset="97%" stopColor={color} stopOpacity={0} />
              </linearGradient>
          }

        </defs>
        {
          isRender &&
          <Area
            type="monotone"
            dataKey="priceInverted"
            stroke={
              color
            }
            strokeWidth={1}
            dot={false}
            fill={`url(#${color + index + data[0]?.priceInverted + data[0]?.timestamp})`}
          />
        }
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default PriceGraph