import DropdownBase from "components/platform/dropdownBase"
import React, { useState } from "react"
import blockchainDictionary from 'constants/blockchainDictionary.json'

const BlockchainDropdown = () => {
    const [blockhain, setBlockhain] = useState(Object.keys(blockchainDictionary)[0])

    return (
        <div className="h-full" style={{ width: 178 }}>
            <DropdownBase
                value={blockhain}
                onChange={setBlockhain}
                options={Object.keys(blockchainDictionary)}
                classNames={
                    {
                        displayRow: ({ open, disabled }) => `transition-all py-1 px-3 hover:bg-black-75 h-full my-auto rounded-base ${open ? "bg-black-75 rounded-bl-none rounded-br-none dropdown-bottom-shadow" : " bg-black-80"}`,
                        optionsContainer: ({ open, disabled }) => `bg-black-75 rounded-bl-base rounded-br-base`,
                        arrow: ({ open, disabled }) => `ml-1`,
                        container: ({ open, disabled }) => `text-smbase h-full `,
                    }
                }
                selectedElement={(data) =>
                    <div className="flex items-center my-auto ">
                        <img className="w-5 h-5 rounded-full mr-3" src={blockchainDictionary[data]?.icon} />
                        {blockchainDictionary[data]?.name}
                    </div>
                }
            >
                {(data) =>
                    <div className="transition-all py-1 px-3 flex items-center hover:bg-black-80 cursor-pointer justify-between">
                        <div className="flex items-center whitespace-nowrap">
                            <img className="w-5 h-5 rounded-full mr-3" src={blockchainDictionary[data]?.icon} />
                            {blockchainDictionary[data]?.name}
                        </div>
                        {
                        blockhain === data &&
                            <div className="h-full w-auto">
                                <img src="/images/token-checkmark.png" className="h-full w-full" />
                            </div>
                        }


                    </div>
                }
            </DropdownBase>
        </div>
    )
}

export default BlockchainDropdown