import React, { MutableRefObject, useEffect, useLayoutEffect, useRef } from 'react'
import useResizeObserver from 'use-resize-observer'

const Accordion = ({ open, children, className = "", startingState = 'closed' }: { open: boolean, children: any, className?: string, startingState?: 'open' | 'closed' }) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>

  const { ref: child } = useResizeObserver({
    onResize: (size) => {
      if (ref.current) {
        ref.current.style.setProperty("--h", `${size.height}px`)
      }
    }
  })

  useLayoutEffect(() => {
    if (startingState === 'open') {
      ref.current.addEventListener('animationend', () => { ref.current.removeAttribute("closing") },
       {once:true})
      ref.current.setAttribute("closing", "")
    }
  }, [])

  useLayoutEffect(() => {
    if (open) {
      ref.current.setAttribute("closing", "")
    }

    return () => {
    }
  }, [open])

  return (
    <div {...(className ? { className } : {})}>
      <div ref={ref} {...{ open }} className='acardion' starting-state={startingState}>
        <div ref={child}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Accordion
