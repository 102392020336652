import React, { StrictMode } from "react"
const LaunchIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.75 24.5H12.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.4506 2.16135C11.3733 3.82539 4.42538 10.4748 10.5007 20.9998H17.5007C23.4497 10.4823 16.6034 3.8348 14.5479 2.16489C14.393 2.03894 14.1995 1.96989 13.9999 1.96924C13.8002 1.9686 13.6063 2.0364 13.4506 2.16135V2.16135Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.079 12.1802L4.65007 16.2949C4.56442 16.3977 4.50348 16.5187 4.47194 16.6487C4.44039 16.7787 4.43908 16.9143 4.4681 17.0449L5.8203 23.1297C5.85253 23.2748 5.92117 23.4092 6.01975 23.5204C6.11832 23.6316 6.24359 23.7158 6.38374 23.7652C6.52388 23.8146 6.67429 23.8274 6.82079 23.8026C6.96729 23.7778 7.10504 23.716 7.22107 23.6232L10.4998 21.0002" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.855 12.1011L23.3498 16.2948C23.4354 16.3976 23.4964 16.5186 23.5279 16.6486C23.5594 16.7786 23.5608 16.9142 23.5317 17.0448L22.1795 23.1296C22.1473 23.2747 22.0787 23.4091 21.9801 23.5203C21.8815 23.6315 21.7562 23.7157 21.6161 23.7651C21.476 23.8145 21.3255 23.8274 21.179 23.8025C21.0326 23.7777 20.8948 23.7159 20.7788 23.6231L17.5 21.0001" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 11.8125C14.7249 11.8125 15.3125 11.2249 15.3125 10.5C15.3125 9.77513 14.7249 9.1875 14 9.1875C13.2751 9.1875 12.6875 9.77513 12.6875 10.5C12.6875 11.2249 13.2751 11.8125 14 11.8125Z" fill="currentColor" />
    </svg>
  )
}

export default LaunchIcon