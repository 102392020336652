import { useAddress, useContractsAddress, useNetwork } from "hooks"
import {
  UAUD,
  UCAD,
  UCHF,
  UCNY,
  UEUR,
  UGBP,
  UHKD,
  UINR,
  UJPY,
  UKRW,
  ULUNA,
  UMNT,
  USDR,
  USGD,
  UTHB,
  UUSD,
} from "constants/constants"
import { useCallback } from "react"
import useURL from "graphql/useURL"
import terraswapConfig from "../constants/terraswap.json"
import axios from "./request"
import { LCDClient, Msg } from "@terra-money/terra.js"
import { AxiosError } from "axios"
import testtokens from "../constants/testnet-tokens.json"

import testpair from "../constants/testpairs.json"
import pairlookup from "../constants/pairlookup.json"
import { useWallet } from "@terra-money/wallet-provider"
import { Type } from "components/platform/context/swap"

interface DenomBalanceResponse {
  height: string
  result: DenomInfo[]
}

interface DenomInfo {
  denom: string
  amount: string
}

interface ContractBalanceResponse {
  height: string
  result: ContractBalance
}

interface ContractBalance {
  balance: string
}

interface GasPriceResponse {
  uluna: string
  uusd: string
  usdr: string
  ukrw: string
  umnt: string
  uaud: string
  ucad: string
  uchf: string
  ucny: string
  ueur: string
  ugbp: string
  uhkd: string
  uinr: string
  ujpy: string
  usgd: string
  uthb: string
}

interface Pairs {
  pairs: Pair[]
}

export interface Pair {
  pair: TokenInfo[]
  contract: string
  liquidity_token: string
}

interface TokenInfo {
  symbol: string
  name: string
  contract_addr: string
}

interface PairsResponse {
  height: string
  result: PairsResult
}

interface PairsResult {
  pairs: PairResult[]
}

interface PairResult {
  liquidity_token: string
  contract_addr: string
  asset_infos: (NativeInfo | AssetInfo)[]
}

interface TokenResult {
  name: string
  symbol: string
  decimals: number
  total_supply: string
  contract_addr: string
}

interface PoolResponse {
  height: string
  result: Pool
}

interface Pool {
  assets: Token[]
  total_share: string
}

interface PoolResult {
  estimated: string
  price1: string
  price2: string
  afterPool: string
  LP: string
  // fromLP: Asset[]
  // text: string
}

interface SimulatedResponse {
  height: string
  result: SimulatedData
}

interface SimulatedData {
  return_amount: string
  offer_amount: string
  commission_amount: string
  spread_amount: string
}

interface TaxResponse {
  height: string
  result: string
}

const blacklist = terraswapConfig.blacklist.map((blacklist) => blacklist.contract_addr)

const isBlacklisted = (info: NativeInfo | AssetInfo) => {
  if (!isAssetInfo(info) || !blacklist.includes(info.token.contract_addr)) {
    return false
  }

  return true
}

export function isAssetInfo(object: any): object is AssetInfo {
  return "token" in object
}

export function isNativeInfo(object: any): object is NativeInfo {
  return "native_token" in object
}

export default () => {
  const { fcd, factory, router, chainID, fee } = useNetwork()
  const { network } = useWallet()
  const address = useAddress()
  const { getSymbol } = useContractsAddress()
  const getURL = useURL()

  // useBalance
  const loadDenomBalance = useCallback(async () => {
    const url = `${fcd}/bank/balances/${address}`
    const res: DenomBalanceResponse = (await axios.get(url)).data
    return res.result
  }, [address, fcd])

  const loadContractBalance = useCallback(
    async (localContractAddr: string) => {
      const url = getURL(localContractAddr, { balance: { address: address } })
      const res: ContractBalanceResponse = (await axios.get(url)).data
      return res.result
    },
    [address, getURL]
  )

  // useGasPrice

  const loadGasPrice = useCallback(
    async (symbol: string) => {
      const symbolName = getSymbol(symbol) || symbol
      const url = `${fcd}/v1/txs/gas_prices`
      const res: GasPriceResponse = (await axios.get(url)).data

      let gasPrice = "0"
      if (
        [UUSD, UKRW, UMNT, ULUNA, USDR, UAUD, UCAD, UCHF, UCNY, UEUR, UGBP, UHKD, UINR, UJPY, USGD, UTHB].includes(
          symbolName
        )
      ) {
        gasPrice = (res as any)?.[symbolName]
      }

      return gasPrice
    },
    [fcd, getSymbol]
  )

  // usePairs
  const loadPairs = useCallback(async () => {
    let result: PairsResult = {
      pairs: [],
    }
    let lastPair: (NativeInfo | AssetInfo)[] | null = null

    try {
      const res: PairsResult = testpair as PairsResult

      if (res.pairs.length !== 0) {
        res.pairs
          .filter((pair) => !isBlacklisted(pair?.asset_infos?.[0]) && !isBlacklisted(pair?.asset_infos?.[1]))
          .forEach((pair) => {
            result.pairs.push(pair)
          })

        return result
      }
    } catch (error) {
      console.log(error)
    }

    while (true) {
      const url = getURL(factory, {
        pairs: { limit: 30, start_after: lastPair },
      })
      const pairs: PairsResponse = (await axios.get(url)).data

      if (!Array.isArray(pairs?.result?.pairs)) {
        // node might be down
        break
      }

      if (pairs.result.pairs.length <= 0) {
        break
      }

      pairs.result.pairs
        .filter((pair) => !isBlacklisted(pair?.asset_infos?.[0]) && !isBlacklisted(pair?.asset_infos?.[1]))
        .forEach((pair) => {
          result.pairs.push(pair)
        })
      lastPair = pairs.result.pairs.slice(-1)[0]?.asset_infos
    }
    return result
  }, [factory, getURL])

  const loadTokensInfo = useCallback(async (): Promise<TokenResult[]> => {
    const res: TokenResult[] = testtokens as TokenResult[]
    return res
  }, [])

  const loadSwappableTokenAddresses = useCallback(async (from: string) => {
    const tokenList = [
      // UST
      "uusd",
      // BTL
      "terra193c42lfwmlkasvcw22l9qqzc5q2dx208tkd7wl",
    ]


    tokenList.splice(tokenList.indexOf(from), 1)
    let res: string[] = tokenList

    // TODO define the to swap tokens here or make a function

    return res
  }, [])

  const loadTokenInfo = useCallback(
    async (contract: string): Promise<TokenResult> => {
      const url = getURL(contract, { token_info: {} })
      const res = (await axios.get(url)).data
      return res.result
    },
    [getURL]
  )

  // usePool
  const loadPool = useCallback(
    async (contract: string) => {
      const url = getURL(contract, { pool: {} })
      const res: PoolResponse = (await axios.get(url)).data
      return res.result
    },
    [getURL]
  )

  // useSwapSimulate
  const querySimulate = useCallback(
    async (variables: { contract: string; msg: any }) => {
      try {
        const { contract, msg } = variables
        const url = getURL(contract, msg)
        const res: SimulatedResponse = (await axios.get(url)).data
        return res
      } catch (error) {
        // @ts-ignore
        const { response }: AxiosError = error
        return response?.data
      }
    },
    [getURL]
  )

  const generateContractMessages = useCallback(
    async (
      query:
        | {
            type: Type.SWAP
            from: string
            to: string
            amount: number | string
            max_spread: number | string
            belief_price: number | string
            sender: string
          }
        | {
            type: Type.PROVIDE
            from: string
            to: string
            fromAmount: number | string
            toAmount: number | string
            slippage: number | string
            sender: string
          }
        | {
            type: Type.WITHDRAW
            lpAddr: string
            amount: number | string
            sender: string
          }
    ) => {
      const { type, ...params } = query



      // @ts-ignore
      const fcd = network["fcd"]

      const lcdclient = new LCDClient({
        URL: fcd,
        chainID: chainID,
        gasPrices: fee,
        gasAdjustment: "1.5",
      })

      let res
      // @ts-ignore
      if (type === Type.SWAP) {
        let decimalsOfFrom = 6

        // get decimals for later processing
        if (params["from"].startsWith("terra1")) {
          const resultfrom = await lcdclient.wasm.contractQuery(
            params["from"],
            { token_info: {} } // query msg
          )

          decimalsOfFrom = resultfrom["decimals"]
        }

        let paircontract = ""

        for (let i = 0; i < pairlookup.length; i++) {
          // @ts-ignore
          if (
            // @ts-ignore
            (pairlookup[i]["token0"].toLowerCase() === params["from"].toLowerCase() &&
              // @ts-ignore
              pairlookup[i]["token1"].toLowerCase() === params["to"].toLowerCase()) ||
            // @ts-ignore
            (pairlookup[i]["token0"].toLowerCase() === params["to"].toLowerCase() &&
              // @ts-ignore
              pairlookup[i]["token1"].toLowerCase() === params["from"].toLowerCase())
          ) {
            paircontract = pairlookup[i]["paircontract"]
          }
        }

      

        // @ts-ignore from token to token
        if (params["from"].startsWith("terra1") && params["to"].startsWith("terra1")) {
          res = [
            {
              type: "wasm/MsgExecuteContract",
              value: {
                coins: [],
                // @ts-ignore
                contract: params["from"],
                execute_msg: {
                  send: {
                    // @ts-ignore
                    amount: (parseFloat(params["amount"]) * 10 ** decimalsOfFrom).toFixed().toString(),
                    contract: paircontract,
                    msg: {
                      swap: {
                        // @ts-ignore
                        belief_price: params["belief_price"],
                        // @ts-ignore
                        max_spread: params["max_spread"],
                      },
                    },
                  },
                },
                sender: params.sender,
              },
            },
          ] as any

          let multiroute = {
            type: "wasm/MsgExecuteContract",
            value: {
              coins: [],
              // @ts-ignore
              contract: params["from"],
              execute_msg: {
                send: {
                  // @ts-ignore
                  amount: (parseFloat(params["amount"]) * 10 ** decimalsOfFrom).toFixed().toString(),
                  contract: router,
                  msg: {
                    execute_swap_operations: {
                      // @ts-ignore
                      offer_amount: (parseFloat(params["amount"]) * 10 ** decimalsOfFrom).toFixed().toString(),
                      minimum_receive: "",
                      operations: [
                        {
                          terra_swap: {
                            ask_asset_info: {
                              native_token: {
                                denom: "uusd",
                              },
                            },
                            offer_asset_info: {
                              token: {
                                // @ts-ignore
                                contract_addr: params["from"],
                              },
                            },
                          },
                        },
                        {
                          terra_swap: {
                            ask_asset_info: {
                              token: {
                                // @ts-ignore
                                contract_addr: params["to"],
                              },
                            },
                            offer_asset_info: {
                              native_token: {
                                denom: "uusd",
                              },
                            },
                          },
                        },
                      ],
                    },
                  },
                },
              },
              sender: params.sender,
            },
          }

          // add to array
          res.push(multiroute)
        }
        // @ts-ignore
        else if (!params["from"].startsWith("terra1") && params["to"].startsWith("terra1")) {
          res = [
            {
              type: "wasm/MsgExecuteContract",
              value: {
                coins: [
                  {
                    // @ts-ignore
                    amount: (parseFloat(params["amount"]) * 10 ** decimalsOfFrom).toString(),
                    // @ts-ignore
                    denom: params["from"],
                  },
                ],
                contract: paircontract,
                execute_msg: {
                  swap: {
                    offer_asset: {
                      // @ts-ignore
                      amount: (parseFloat(params["amount"]) * 10 ** decimalsOfFrom).toString(),
                      info: {
                        native_token: {
                          // @ts-ignore
                          denom: params["from"],
                        },
                      },
                    },
                    // @ts-ignore
                    belief_price: params["belief_price"],
                    // @ts-ignore
                    max_spread: params["max_spread"],
                  },
                },
                sender: params.sender,
              },
            },
          ] as any

          let multiroute = {
            type: "wasm/MsgExecuteContract",
            value: {
              coins: [
                {
                  // @ts-ignore
                  amount: (parseFloat(params["amount"]) * 10 ** decimalsOfFrom).toString(),
                  // @ts-ignore
                  denom: params["from"],
                },
              ],
              // @ts-ignore
              contract: router,
              execute_msg: {
                execute_swap_operations: {
                  // @ts-ignore
                  offer_amount: (parseFloat(params["amount"]) * 10 ** decimalsOfFrom).toFixed().toString(),
                  minimum_receive: "",
                  operations: [
                    {
                      terra_swap: {
                        ask_asset_info: {
                          native_token: {
                            denom: "uusd",
                          },
                        },
                        offer_asset_info: {
                          native_token: {
                            // @ts-ignore
                            denom: params["from"],
                          },
                        },
                      },
                    },
                    {
                      terra_swap: {
                        ask_asset_info: {
                          token: {
                            // @ts-ignore
                            contract_addr: params["to"],
                          },
                        },
                        offer_asset_info: {
                          native_token: {
                            denom: "uusd",
                          },
                        },
                      },
                    },
                  ],
                },
              },
              sender: params.sender,
            },
          }

          // add to array
          res.push(multiroute)

          // @ts-ignore
        } else if (params["from"].startsWith("terra1") && !params["to"].startsWith("terra1")) {
          res = [
            {
              type: "wasm/MsgExecuteContract",
              value: {
                coins: [],
                // @ts-ignore
                contract: params["from"],
                execute_msg: {
                  send: {
                    // @ts-ignore
                    amount: (parseFloat(params["amount"]) * 10 ** decimalsOfFrom).toFixed().toString(),
                    contract: paircontract,
                    msg: {
                      swap: {
                        // @ts-ignore
                        belief_price: params["belief_price"],
                        // @ts-ignore
                        max_spread: params["max_spread"],
                      },
                    },
                  },
                },
                sender: params.sender,
              },
            },
          ] as any

          let multiroute = {
            type: "wasm/MsgExecuteContract",
            value: {
              coins: [],
              // @ts-ignore
              contract: params["from"],
              execute_msg: {
                send: {
                  // @ts-ignore
                  amount: (parseFloat(params["amount"]) * 10 ** decimalsOfFrom).toFixed().toString(),
                  contract: router,
                  msg: {
                    execute_swap_operations: {
                      // @ts-ignore
                      offer_amount: (parseFloat(params["amount"]) * 10 ** decimalsOfFrom).toFixed().toString(),
                      minimum_receive: "",
                      operations: [
                        {
                          terra_swap: {
                            ask_asset_info: {
                              native_token: {
                                denom: "uusd",
                              },
                            },
                            offer_asset_info: {
                              token: {
                                // @ts-ignore
                                contract_addr: params["from"],
                              },
                            },
                          },
                        },
                        {
                          terra_swap: {
                            ask_asset_info: {
                              native_token: {
                                // @ts-ignore
                                denom: params["to"],
                              },
                            },
                            offer_asset_info: {
                              native_token: {
                                denom: "uusd",
                              },
                            },
                          },
                        },
                      ],
                    },
                  },
                },
              },
              sender: params.sender,
            },
          }

          // add to array
          res.push(multiroute)
        }
        // from stable stable
        else {
          res = [
            {
              type: "wasm/MsgExecuteContract",
              value: {
                coins: [
                  {
                    // @ts-ignore
                    amount: (parseFloat(params["amount"]) * 10 ** decimalsOfFrom).toString(),
                    // @ts-ignore
                    denom: params["from"],
                  },
                ],
                contract: paircontract,
                execute_msg: {
                  swap: {
                    offer_asset: {
                      // @ts-ignore
                      amount: (parseFloat(params["amount"]) * 10 ** decimalsOfFrom).toString(),
                      info: {
                        native_token: {
                          // @ts-ignore
                          denom: params["from"],
                        },
                      },
                    },
                    // @ts-ignore
                    belief_price: params["belief_price"],
                    // @ts-ignore
                    max_spread: params["max_spread"],
                  },
                },
                sender: params.sender,
              },
            },
          ] as any

          let multiroute = {
            type: "wasm/MsgExecuteContract",
            value: {
              coins: [
                {
                  // @ts-ignore
                  amount: (parseFloat(params["amount"]) * 10 ** decimalsOfFrom).toString(),
                  // @ts-ignore
                  denom: params["from"],
                },
              ],
              // @ts-ignore
              contract: router,
              execute_msg: {
                execute_swap_operations: {
                  // @ts-ignore
                  offer_amount: (parseFloat(params["amount"]) * 10 ** decimalsOfFrom).toFixed().toString(),
                  minimum_receive: "",
                  operations: [
                    {
                      terra_swap: {
                        ask_asset_info: {
                          native_token: {
                            denom: "uusd",
                          },
                        },
                        offer_asset_info: {
                          native_token: {
                            // @ts-ignore
                            denom: params["from"],
                          },
                        },
                      },
                    },
                    {
                      terra_swap: {
                        ask_asset_info: {
                          native_token: {
                            // @ts-ignore
                            denom: params["to"],
                          },
                        },
                        offer_asset_info: {
                          native_token: {
                            denom: "uusd",
                          },
                        },
                      },
                    },
                  ],
                },
              },
              sender: params.sender,
            },
          }

          // add to array
          res.push(multiroute)
        }
      }

      // provide
      else if (type === Type.PROVIDE) {
        let decimalsOfFrom = 6
        let decimalsOfTo = 6
        // get decimals for later processing
        if (params["from"].startsWith("terra1")) {
          const resultfrom = await lcdclient.wasm.contractQuery(
            params["from"],
            { token_info: {} } // query msg
          )

          decimalsOfFrom = resultfrom["decimals"]
        }

        // get decimals for later processing
        if (params["to"].startsWith("terra1")) {
          const resultfrom = await lcdclient.wasm.contractQuery(
            params["to"],
            { token_info: {} } // query msg
          )

          decimalsOfTo = resultfrom["decimals"]
        }

        let paircontract = ""

        for (let i = 0; i < pairlookup.length; i++) {
          // @ts-ignore
          if (
            // @ts-ignore
            (pairlookup[i]["token0"].toLowerCase() === params["from"].toLowerCase() &&
              // @ts-ignore
              pairlookup[i]["token1"].toLowerCase() === params["to"].toLowerCase()) ||
            // @ts-ignore
            (pairlookup[i]["token0"].toLowerCase() === params["to"].toLowerCase() &&
              // @ts-ignore
              pairlookup[i]["token1"].toLowerCase() === params["from"].toLowerCase())
          ) {
            paircontract = pairlookup[i]["paircontract"]
          }
        }

        // @ts-ignore
        if (params["from"].startsWith("terra1")) {
          res = [
            {
              type: "wasm/MsgExecuteContract",
              value: {
                coins: [],
                // @ts-ignore
                contract: params["from"],
                execute_msg: {
                  increase_allowance: {
                    // @ts-ignore
                    amount: (parseFloat(params["fromAmount"]) * 10 ** decimalsOfFrom).toFixed(0).toString(),
                    spender: paircontract,
                  },
                },
                sender: params.sender,
              },
            },
            {
              type: "wasm/MsgExecuteContract",
              value: {
                coins: [
                  {
                    // @ts-ignore
                    amount: (parseFloat(params["toAmount"]) * 10 ** decimalsOfTo).toString(),
                    // @ts-ignore
                    denom: params["to"],
                  },
                ],
                contract: paircontract,
                execute_msg: {
                  provide_liquidity: {
                    assets: [
                      {
                        // @ts-ignore
                        amount: (parseFloat(params["fromAmount"]) * 10 ** decimalsOfFrom).toFixed(0).toString(),
                        info: {
                          token: {
                            // @ts-ignore
                            contract_addr: params["from"],
                          },
                        },
                      },
                      {
                        // @ts-ignore
                        amount: (parseFloat(params["toAmount"]) * 10 ** decimalsOfTo).toString(),
                        info: {
                          native_token: {
                            // @ts-ignore
                            denom: params["to"],
                          },
                        },
                      },
                    ],
                    // @ts-ignore
                    slippage_tolerance: params["slippage"],
                  },
                },
                sender: params.sender,
              },
            },
          ] as any
        }
        // @ts-ignore
        if (params["to"].startsWith("terra1")) {
          res = [
            {
              type: "wasm/MsgExecuteContract",
              value: {
                coins: [],
                // @ts-ignore
                contract: params["to"],
                execute_msg: {
                  increase_allowance: {
                    // @ts-ignore
                    amount: (parseFloat(params["toAmount"]) * 10 ** decimalsOfTo).toFixed(0).toString(),
                    spender: paircontract,
                  },
                },
                sender: params.sender,
              },
            },
            {
              type: "wasm/MsgExecuteContract",
              value: {
                coins: [
                  {
                    // @ts-ignore
                    amount: (parseFloat(params["fromAmount"]) * 10 ** decimalsOfFrom).toString(),
                    // @ts-ignore
                    denom: params["from"],
                  },
                ],
                contract: paircontract,
                execute_msg: {
                  provide_liquidity: {
                    assets: [
                      {
                        // @ts-ignore
                        amount: (parseFloat(params["toAmount"]) * 10 ** decimalsOfTo).toFixed(0).toString(),
                        info: {
                          token: {
                            // @ts-ignore
                            contract_addr: params["to"],
                          },
                        },
                      },
                      {
                        // @ts-ignore
                        amount: (parseFloat(params["fromAmount"]) * 10 ** decimalsOfFrom).toString(),
                        info: {
                          native_token: {
                            // @ts-ignore
                            denom: params["from"],
                          },
                        },
                      },
                    ],
                    // @ts-ignore
                    slippage_tolerance: params["slippage"],
                  },
                },
                sender: params.sender,
              },
            },
          ] as any
        }
      }
      // withdraw
      else {
        //const url = `${service}/tx/${type}`.toLowerCase()
        //res = (await axios.get(url, { params })).data

        let paircontract = ""

        for (let i = 0; i < pairlookup.length; i++) {
          // @ts-ignore
          if (pairlookup[i]["lpAddr"].toLowerCase() === params["lpAddr"].toLowerCase()) {
            paircontract = pairlookup[i]["paircontract"]
          }
        }

        res = [
          {
            type: "wasm/MsgExecuteContract",
            value: {
              coins: [],
              // @ts-ignore
              contract: params["lpAddr"],
              execute_msg: {
                send: {
                  // @ts-ignore
                  amount: (parseFloat(params["amount"]) * 1000000).toFixed(0).toString(),
                  contract: paircontract,
                  msg: "eyJ3aXRoZHJhd19saXF1aWRpdHkiOnt9fQ==",
                },
              },
              sender: params.sender,
            },
          },
        ]
      }

      return res.map((data: Msg.Data | Msg.Data[]) => {
        return (Array.isArray(data) ? data : [data]).map((item: Msg.Data) => Msg.fromData(item))
      })
    },
    []
  )

  // useTax
  const loadTaxInfo = useCallback(
    async (symbol: string) => {
      if (!symbol) {
        return ""
      }
      const symbolName = getSymbol(symbol) || symbol
      const url = `${fcd}/treasury/tax_cap/${symbolName}`
      const res: TaxResponse = (await axios.get(url)).data
      return res.result
    },
    [fcd, getSymbol]
  )

  const loadTaxRate = useCallback(async () => {
    const url = `${fcd}/treasury/tax_rate`
    const res: TaxResponse = (await axios.get(url)).data
    return res.result
  }, [fcd])

  return {
    loadDenomBalance,
    loadContractBalance,
    loadGasPrice,
    loadPairs,
    loadTokensInfo,
    loadSwappableTokenAddresses,
    loadTokenInfo,
    loadPool,
    querySimulate,
    generateContractMessages,
    loadTaxInfo,
    loadTaxRate,
  }
}
